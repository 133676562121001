import React from 'react';

const TruIDIframe = (props) =>{
    //, width:'400px'
    return <div dangerouslySetInnerHTML={{ __html: `<iframe frameBorder="0" height="650px" src=${props.src} width=${props.width+'%'}} />`}} />;
    // return <div style={{position:'relative', top:0, left:0}}>
    //     <iframe
    //         title={''}
    //         frameBorder="0"
    //         height="650px"
    //         src={props.src}
    //         width={props.width+'%'}
    //     />
    // </div>
};

export default TruIDIframe;