import React from 'react';

const LoadingBox = (props) =>{
    return (
        <div className="loading" style={{fontSize:props.size, color:props.color}}>
            <i className="fa fa-spinner fa-spin"></i> Loading...
        </div>
    )
};

export {LoadingBox}