import axios from "axios";

// const { REACT_APP_API_URL } = process.env;
// const API_ADDRESS = "http://192.168.2.218:4000/";
// const API_LOCAL = "http://localhost:4000/";
// const API = REACT_APP_API_URL === 'staging' ? API_ADDRESS : API_LOCAL;
const { REACT_APP_NGROK_URL, REACT_APP_LIVE } = process.env;
const API_ADDRESS = `http://${REACT_APP_NGROK_URL}/`;
const API_LOCAL =  "https://api.amabuzz.com/"; //"http://localhost:3000/";//   
const API = REACT_APP_LIVE === 'live' ? API_ADDRESS : API_LOCAL;
export const notify = (aClass, aMessage) =>  {
  // return dispatch => {
  //   dispatch(notification({
  //     type: aClass,
  //     text: aMessage
  //   }))
  //   setTimeout(() => {
  //     dispatch(notification({
  //       type: '',
  //       text: ''
  //     }))
  //   }, 2500);
  // }
}
// loader
export const loader = (aOption)=> {
  return {
    type: 'LOADER',
    data: aOption
  }
}
//Application Actions
// , {
//   headers:{
//       'Access-Control-Allow-Origin':'*',
//   },
// }
export const saveGeneralInfo = (generalInfo) => async dispatch => {
  try {
    const { data } = await axios.post(`${API}customers`, generalInfo);
    // generalInfo.customerid = data._id;
    // generalInfo.creditScore = data.creditScore;
    dispatch({type:'ADD_APP_ID', payload:data._id});
  } catch(e) {
    dispatch({
      type:'SAVE_INFORMATION_ERROR',
      payload:e.response && e.response.data.message ? e.response.data.message : e.message
    });
  }
};

export const saveAddressInfo = (addressInfo) => async (dispatch,getState) => {
  const {
    applicationReducer: { selectedApp }
  } = getState();
  try {
    const { data } = await axios.put(`${API}customers/${selectedApp.appid}/`,addressInfo);
    //dispatch({type:'SAVE_ADDRESS_INFO', payload:addressInfo});
  } catch(e) {
    dispatch({
      type:'SAVE_INFORMATION_ERROR',
      payload:e.response && e.response.data.message ? e.response.data.message : e.message
    });
  }
};

export const saveEmployerInfo = (employerInfo) => async (dispatch, getState) => {
  const {
    applicationReducer: {selectedApp}
  } = getState();
  try {
    const { data } = await axios.put(`${API}customers/${selectedApp.appid}/`, employerInfo);
    //dispatch({type:'SAVE_EMPLOYER_INFO', payload:employerInfo});
  } catch(e) {
    dispatch({
      type:'SAVE_INFORMATION_ERROR',
      payload:e.response & e.response.data ? e.response.data.message : e.message
    });
  }
};

export const saveBankingInfo = (bankingInfo) => async (dispatch, getState) => {
  const {
    applicationReducer: {selectedApp}
  } = getState();
  try {
    const { data } = await axios.put(`${API}customers/${selectedApp.appid}/`, bankingInfo);
    //dispatch({type:'SAVE_BANKING_INFO', payload:bankingInfo});
  } catch(e) {
    dispatch({
      type:'SAVE_INFORMATION_ERROR',
      payload:e.response & e.response.data.message ? e.response.data.message : e.message
    });
  }
};
// , {
//   headers:{
//       'Access-Control-Allow-Origin':'*',
//   },
// }
export const saveIncomeExpenses = (info, Income, Expenses) => async (dispatch, getState) => {
  const {
    applicationReducer: { selectedApp }
  } = getState();
  try {
    const {data} = await axios.put(`${API}customers/affordability/${selectedApp.appid}`, info);
    dispatch({type:'SAVE_INCOME_EXPENSES', payload:{Income, Expenses}});
  } catch(e) {
    dispatch({
      type:'SAVE_INFORMATION_ERROR',
      payload:e.response & e.response.data.message ? e.response.data.message : e.message
    });
  }
};

export const saveDocuments = (documents) => dispatch => {
  dispatch({type:'SAVE_DOCUMENTS', payload:documents});
};
// ,{
//   headers:{
//     'Access-Control-Allow-Origin':'*',
//   },
// }
export const saveQuestionsAns = (questionsAns) => async dispatch => {
  //dispatch({type:'SAVE_QUESTIONS_ANSWERS', payload:questionsAns});
  try{
    const {data} = await axios.post(`${API}compuscan/saveCustomerCompuscanAnswers`,questionsAns);
    dispatch({type:'SAVE_QUESTIONS_ANSWERS_SUCCESS', payload:data});
  }catch(e){
    dispatch({type:'SAVE_QUESTIONS_ANSWERS_ERROR', payload:e.response && e.response.data.message ? e.response.data.message : e.message});
  }
};
// , {
//   withCredentials:false,
//   headers:{
//       'Access-Control-Allow-Origin':'*',
//       'Access-Control-Allow-Methods' : 'GET, POST, PUT, DELETE, OPTIONS',
//   },
// }
export const registerCustomer = (customer, promoCode) => async dispatch => {
  try {
    const response = await axios.post(`${API}customers/register`, {customer, promoCode});
    if(response.status === 200){
      dispatch({type:'CUSTOMER_REGISTER_SUCCESS', payload:response.data});
      //dispatch({type:'CUSTOMER_LOGIN_SUCCESS', payload:response.data});
    }else if(response.status === 201){
      if(response.data.message === 'Customer already registered'){
        dispatch({
          type:'CUSTOMER_REGISTER_ERROR',
          payload:response.data.message
        });
      }else{ //if(response.data.otpSent.message === 'There was an error sending your One Time Pin code, please try again.'){
        dispatch({
          type:'OTP_ERROR_SENDING',
          payload:response.data
        })
      }
    }
  } catch(e) {
    let msg = e.response.data.substr(e.response.data.indexOf("<pre>")+5, 39);
    console.log(msg);
    dispatch({
      type:'CUSTOMER_REGISTER_ERROR',
      payload:e.response & e.response.data.message || msg ? e.response.data.message || msg : e.message
    });
  }
};
export const resendOtp = (customer, target) => async dispatch => {
  if(target === 'resend1'){
    try {
      const response = await axios.post(`${API}customers/resendOtp`, customer);
      if(response.status === 200){
        dispatch({type:'OTP_RESEND_SUCCESS1', payload:response.data});
        //dispatch({type:'CUSTOMER_LOGIN_SUCCESS', payload:response.data});
      }else if(response.status === 201){
        dispatch({
            type:'OTP_RESEND_ERROR1',
            payload:response.data
        });
      }
    } catch(e) {
      let msg = e.response.data.substr(e.response.data.indexOf("<pre>")+5, 39);
      const message = e.response & e.response.data.message || msg ? e.response.data.message || msg : e.message;
      console.log(msg);
      dispatch({
        type:'OTP_RESEND_ERROR',
        payload:{OTP:[], message}
      });
    }
  }else if(target === 'resend2'){
    try {
      const response = await axios.post(`${API}customers/resendOtp`, customer);
      if(response.status === 200){
        dispatch({type:'OTP_RESEND_SUCCESS2', payload:response.data});
        //dispatch({type:'CUSTOMER_LOGIN_SUCCESS', payload:response.data});
      }else if(response.status === 201){
        dispatch({
            type:'OTP_RESEND_ERROR2',
            payload:response.data
        });
      }
    } catch(e) {
      let msg = e.response.data.substr(e.response.data.indexOf("<pre>")+5, 39);
      const message = e.response & e.response.data.message || msg ? e.response.data.message || msg : e.message;
      console.log(msg);
      dispatch({
        type:'OTP_RESEND_ERROR',
        payload:{OTP:[], message}
      });
    }
  }
};
export const verifyOtp = (customer) => async dispatch => {
  try {
    const response = await axios.post(`${API}customers/verify`, customer);
    if(response.status === 200){
      dispatch({type:'OTP_VERIFY_SUCCESS', payload:response.data});
    }else if(response.status === 201){
      dispatch({
          type:'OTP_VERIFY_ERROR',
          payload:response.data
      });
    }
  } catch(e) {
    let msg = e.response.data.substr(e.response.data.indexOf("<pre>")+5, 39);
    const message = e.response & e.response.data.message || msg ? e.response.data.message || msg : e.message;
    console.log(msg);
    dispatch({
      type:'OTP_VERIFY_ERROR',
      payload:{OTP:[], message}
    });
  }
};
export const sendPassword = (idnumber) => async dispatch => {
  dispatch({type:'CUSTOMER_RESET_PWD_REQUEST'})
  try{
    const response = await axios.post(`${API}customers/sendpassword`,{RSAIDNumber:idnumber});
    if(response.status === 200){
      dispatch({type:'CUSTOMER_RESET_PWD_SUCCESS', payload:response.data.message});
    }else if(response.status === 201){
      dispatch({type:'CUSTOMER_RESET_PWD_ERROR', payload:response.data.message});
    }
    console.log('response: ', response);
    //dispatch({type:'CUSTOMER_RESET_PWD_SUCCESS', payload:response.data});
  }catch(e){
    console.log('SendpwdError: ', e);
    dispatch({type:'CUSTOMER_RESET_PWD_ERROR', payload:e.message});
  }
}
export const activateEmail = (data) => async dispatch => {
  dispatch({type:'ACTIVATE_ACCOUNT_REQUEST'})
  try{
    const response = await axios.post(`${API}customers/emailactivate`,data);
    if(response.status === 200){
      dispatch({type:'ACTIVATE_ACCOUNT_SUCCESS', payload:response.data.message});
    }else if(response.status === 201){
      dispatch({type:'ACTIVATE_ACCOUNT_ERROR', payload:response.data.message});
    }
    console.log('response: ', response);
  }catch(e){
    console.log('ActivateError: ', e);
    dispatch({type:'ACTIVATE_ACCOUNT_ERROR', payload:e.message});
  }
}
export const fetchContract = (data) => async dispatch =>{
  dispatch({type:'FETCH_CONTRACT_REQUEST'});
  try{
    const response = await axios.post(`${API}customers/viewContract`, data);
    if(response.status === 200){
      dispatch({type:'FETCH_CONTRACT_SUCCESS', payload:response.data});
    }else if(response.status === 201){
      dispatch({type:'FETCH_CONTRACT_ERROR', payload:response.data});
    }
  }catch(e){
    dispatch({type:'FETCH_CONTRACT_ERROR', payload:{message:'', error:e.message, CustomerContract:''}});
  }
}
export const resetPword = (data) => async dispatch =>{
  dispatch({type:'CUSTOMER_NEW_PWD'});
  try{
    const response  = await axios.post(`${API}customers/resetpassword`,data);
    if(response.status === 200){
      dispatch({type:'CUSTOMER_NEW_PWD_SUCCESS', payload:response.data.message});
    }else if(response.status === 201){
      dispatch({type:'CUSTOMER_NEW_PWD_ERROR', payload:response.data.message});
    }
  }catch(e){
    dispatch({type:'CUSTOMER_NEW_PWD_ERROR', payload:e.message});
  }
}
function* myGenie(customer, reCaptchaValue, promoCode) {
  console.log('CUSTOMERERERERERERER::: ', customer)
  yield axios.post(`${API}customers/login/`, {customer, reCaptchaValue, promoCode});
  // const { RSAIDNumber, Forename, Surname } = customer;
  // yield axios.post(`http://localhost:4000/compuscan`, {
  //   Identity_number: RSAIDNumber,
  //   Forename: Forename,
  //   Surname: Surname
  // });
}

export const loginCustomer = (customer, reCaptchaValue, promoCode) => dispatch => {
  try {
    // const { data } = await axios.post('http://localhost:4000/customers/login', customer);
    // const { RSAIDNumber, firstName, lastName } = data;
    // console.log('DATA===>> ', data)
    // const { data2 } = await axios.post(`http://localhost:4000/compuscan`,{Identity_number:RSAIDNumber, Forename:firstName, Surname: lastName });
    // console.log('DATAAAAA:: ', data2);
    const gen = myGenie(customer, reCaptchaValue, promoCode);
    const getCustomer = gen.next().value;
    console.log('----->', getCustomer)
    getCustomer.then(res => {
      // const a = Object.assign(customer, {
      //   Identity_number: res.data.custRet.RSAIDNumber,
      //   Forename: res.data.custRet.firstName,
      //   Surname: res.data.custRet.lastName
      // })
      //const getCompuscan = gen.next(a).value;
      //console.log('>>>>>', getCompuscan)
      if(res.status === 200){
        if(res.data.SMSSentID === 'incomplete'){
          dispatch({type:'CUSTOMER_TEMP_LOGIN_SUCCESS', payload:res.data});
        }else{
          dispatch({type:'CUSTOMER_LOGIN_SUCCESS', payload: res.data});
        }
      }else if(res.status === 201){
        dispatch({
          type:'CUSTOMER_LOGIN_ERROR',
          payload: res.data.message
        })
      }
      // getCompuscan.then(comRes => {
      //   dispatch({type:'CUSTOMER_LOGIN_SUCCESS', payload: res.data});
      //   dispatch({type:'LOAD_COMPUSCAN_DATA', payload: comRes.data});
      // });
    });
    // gen.next().value
  } catch(e) {
    let msg = e.response.data.substr(e.response.data.indexOf("<pre>")+5, 34);
    console.log(msg);
    dispatch({
      type:'CUSTOMER_LOGIN_ERROR',
      payload:e.response & e.response.data.message || msg ? e.response.data.message || msg : e.message
    });
  }
};


export const addData = (info) => dispatch =>{
  //console.log(info);
  dispatch({type:'SAVE_INFO_REQUEST', payload:info});
};
export const addIncomeExpense = (info) => dispatch =>{
  dispatch({type:'SAVE_INC_EXP_REQUEST', payload:info});
}
export const loadCompuscan = (id) => async dispatch => {
  try{
    const {data} = await axios.get(`${API}compuscan/getReportFromDB/${id}`);
    dispatch({type:'LOAD_COMPUSCAN_DATA', payload:data})
  }catch(e){
    let msg = e.response.data.substr(e.response.data.indexOf("<pre>")+5, 34);
    dispatch({type:'LOAD_COMPUSCAN_DATA_ERROR', payload:e.response & e.response.data.message || msg ? e.response.data.message || msg : e.message});
  }
};
export const loadFreshCompuscan = (RSAIDNumber, firstName, lastName) => async (dispatch,getState) => {
  // const {
  //   applicationReducer: { selectedApp, customerSignIn }
  // } = getState();
  // , {
  //   headers:{
  //       'Access-Control-Allow-Origin':'*',
  //       'Access-Control-Allow-Methods' : 'GET, POST, PUT, DELETE, OPTIONS',
  //   },
  // }
  try{
    const COMPAPI = "https://amabuzz.ngrok.io/"; //"http://localhost:4000/";//   //API
    const {data} = await axios.post(`${COMPAPI}compuscan`,{Identity_number:RSAIDNumber, Forename:firstName, Surname: lastName });
    console.log('DATAAAAA:: ', data);
    dispatch({type:'LOAD_COMPUSCAN_DATA', payload:data});
  }catch(e){
    console.log('e::', e.response);
    let msg = e.response.data.substr(e.response.data.indexOf("<pre>")+5, 34);
    dispatch({type:'LOAD_COMPUSCAN_DATA_ERROR', payload:e.response & e.response.data.message || msg ? e.response.data.message || msg : e.message});
  }
};
export const fetchMinCredit = () => async dispatch =>{
  try{
    let url = `${API}customers/appMessageSettings`;
    const result = await axios(url);
    let { data } = result;
    console.log('appMessage???:::: ', data);
    dispatch({type:'LOAD_MIN_SCORE', payload:data[0].minimCreditScore});
  }catch(e){
    dispatch({type:'LOAD_MIN_SCORE_ERROR', payload:e.message});
  }
  
};
export const fetchPrequalifiedIDS = () => async dispatch =>{
  try{
    let url = `${API}customers/prequalifiedids`;
    const result = await axios(url);
    let { data } = result;
    console.log('preQuIDS???:::: ', data);
    dispatch({type:'LOAD_PREQU_IDS', payload:data});
  }catch(e){
    dispatch({type:'LOAD_PREQU_IDS_ERROR', payload:e.message});
  }
  
};
export const fetchAmortizations = (loanParams) => async dispatch =>{
  try{
    const COMPAPI = "https://amabuzz.ngrok.io/"; //
    let url = `${COMPAPI}customers/amortizations`;
    const { data } = await axios.post(url, loanParams);
    dispatch({type:'AMORTIZATIONS_REQUEST_SUCCESS', payload:data});
  }catch(e){
    dispatch({type:'AMORTIZATIONS_REQUEST_FAILED', payload:e.message});
  }
}
export const loadTruIDURL = (obj) => async (dispatch,getState) => {
  // const {
  //   applicationReducer: { selectedApp, customerSignIn }
  // } = getState();
  // , {
  //   headers:{
  //       'Access-Control-Allow-Origin':'*',
  //   },
  // }
  try{
    const {data} = await axios.post(`${API}truID/consultant-api/90DayTransactions`,obj);
    console.log('truIDDATAAA:: ', data);
    dispatch({type:'LOAD_TRUID_DATA', payload:data})
  }catch(e){
    console.log('e::', e.response);
    let msg = e.response.data.substr(e.response.data.indexOf("<pre>")+5, 34);
    dispatch({type:'LOAD_TRUID_DATA_ERROR', payload:e.response & e.response.data.message || msg ? e.response.data.message || msg : e.message});
  }
};
// , {
//   headers:{
//       'Access-Control-Allow-Origin':'*',
//   },
// }
export const SaveAppStatus = (info) => async (dispatch,getState) =>{
  const COMPAPI = "https://amabuzz.ngrok.io/";
  const {
    applicationReducer: { selectedApp, customerSignIn, truID }
  } = getState();
  let url = `${COMPAPI}truID/consultant-api/getTransactionsByCustomerRSAIdNumber/${selectedApp.RSAIDNumber}`
  if(customerSignIn.account.length === 0){
    dispatch({type:'UPDATE_ACCOUNNT', payload:info});
  }
  try{ //, info truID.replace('https://www.truidconnect.io/','')
    const { data } = await axios.put(`${API}customers/appStatus/${selectedApp.appid}`, {applicationStatus:info.applicationStatus});
    //const response = await axios.get(url);
  }catch(e){
    dispatch({type:'UPDATE_ACCOUNNT_ERROR', payload:e.response & e.response.data.message ||  e.response.data.message || e.message});
  }
}
