import React  from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {sendPassword} from '../reducers/core/index';
import { LoadingBox } from './Loading';
import { MessageBox } from './MessageBox';

const ForgotPassword = (props) =>{
    //window.scrollTo(0,0);
    const sendPwd = (event) => {
        event.preventDefault();
        let RSAIDNumber = document.getElementById("IDNumber").value;
        if(RSAIDNumber !== ''){
            props.sendPassword(RSAIDNumber);
        }
    }
    return(
        <main id="main">
            <div className="container" data-aos="fade-up">
                <section id="login" className="login">
                    <div className="section-title pt">
                        <p>Forgot Password</p>
                    </div>
                    <p>Enter your ID number to reset your password?</p>
                    <div className="col-md-12 row">
                        <div className="col-md-6">
                            <form className="login-form">
                                {props.loading && <LoadingBox />}
                                {props.success && <MessageBox>{props.message}</MessageBox>}
                                {props.error && <MessageBox variant="danger">{props.error}</MessageBox>}
                                <div className="">
                                    <div className="col-md-12 form-group">
                                        {/* <label>ID Number</label> */}
                                        <input type="text" name="IDNumber" width="100%" className="form-control" id="IDNumber" placeholder="ID Number" data-rule="minlen:4" maxLength="13" data-msg="Please enter 13 digits ID Number" />
                                    </div>
                                </div>
                                <div className='col-md-12 text-right'>
                                    <button type='submit' className='get-started-btn' onClick={sendPwd}>Send Password</button>
                                </div>
                                <div className="col-md-6 form-group">
                                    <Link to="/login">Back to log in</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};
const mapStateToProps = state =>({
    loading: state.applicationReducer.loading,
    error: state.applicationReducer.error,
    success: state.applicationReducer.success,
    message: state.applicationReducer.message,
});

const mapDispatchToProps = {
    sendPassword,
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);