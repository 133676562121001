import React, { useEffect, useState } from 'react';
import { MessageBox } from './MessageBox';
import ProgressBr from './Progress';
import LoanSummary from './LoanSummary';
import { useSelector } from 'react-redux';

const lookup = {
    "weekly": [
      { id: 'Monday', text: 'Monday' },
      { id: 'Tuesday', text: 'Tuesday' },
      { id: 'Wednesday', text: 'Wednesday' },
      { id: 'Thursday', text: 'Thursday' },
      { id: 'Friday', text: 'Friday' },
      { id: 'Saturday', text: 'Saturday' },
      { id: 'Sunday', text: 'Sunday' }
    ],
    "fortnightly": [
      { id: '2nd Monday', text: '2nd Monday' },
      { id: '2nd Tuesday', text: '2nd Tuesday' },
      { id: '2nd Wednesday', text: '2nd Wednesday' },
      { id: '2nd Thursday', text: '2nd Thursday' },
      { id: '2nd Friday', text: '2nd Friday' },
      { id: '2nd Sunday', text: '2nd Sunday' },
      { id: '2nd Saturday', text: '2nd Saturday' }
    ],
    "monthly": [
        { id: '1', text: '1' },
        { id: '2', text: '2' },
        { id: '3', text: '3' },
        { id: '4', text: '4' },
        { id: '5', text: '5' },
        { id: '6', text: '6' },
        { id: '7', text: '7' },
        { id: '8', text: '8' },
        { id: '9', text: '9' },
        { id: '10', text: '10' },
        { id: '11', text: '11' },
        { id: '12', text: '12' },
        { id: '13', text: '13' },
        { id: '14', text: '14' },
        { id: '15', text: '15' },
        { id: '16', text: '16' },
        { id: '17', text: '17' },
        { id: '18', text: '18' },
        { id: '19', text: '19' },
        { id: '20', text: '20' },
        { id: '21', text: '21' },
        { id: '22', text: '22' },
        { id: '23', text: '23' },
        { id: '24', text: '24' },
        { id: '25', text: '25' },
        { id: '26', text: '26' },
        { id: '27', text: '27'},
        { id: '28', text: '28'},
        { id: '29', text: '29' },
        { id: '30', text: '30'},
        { id: '31', text: '31'}
    ]
  }
const EmployerInfo = ({nextStep, prevStep, error, values}) => {
    //window.scrollTo(0,0);
    const [employerName, setemplooyerName] = useState(values.employerName);
    const [employerAddress1, setemployerAddres1] = useState(values.employerAddress1);
    //const [workNumber, setworkNumber] = useState(values.workNumber);
    const [employerAddressCity, setemployerAddressCity] = useState(values.employerAddressCity);
    const [employerAddressPostalCode, setemployerPostalCode] = useState(values.employerAddressPostalCode);
    const [employerContactNumber, setemployerContactNumber] = useState(values.employerContactNumber);
    const [salaryDay, setsalryDay] = useState(values.salaryDay);
    const [yearEmployed, setyearEmployed] = useState(values.yearEmployed);
    const [monthEmployed, setmonthEmployed] = useState(values.monthEmployed);
    const [occupation, setoccupation] = useState(values.occupation);
    const [salaryFrequency, setsalaryFrequence] = useState(values.salaryFrequency || 'monthly');
    const [employmentNumber, setemploymentNumber] = useState(values.employmentNumber);
    const [taxNumber, setTaxNumber] = useState(values.taxNumber);
    const [firstTIme, setFirstTIme] = useState(0);
    const selectedApp = useSelector(state => state.applicationReducer.selectedApp);
    const {affordability} = selectedApp;
    const Continue = e => {
        e.preventDefault();
        if(handleValidation()){
           nextStep({employerName, employerAddress1, employerAddressCity, employerAddressPostalCode,
                    employerContactNumber, salaryDay, yearEmployed, monthEmployed, occupation, salaryFrequency, employmentNumber, taxNumber, EmploymentVerificationStatus:'Positively Verified'});
        }

    }
    const Previous = e => {
        e.preventDefault();
        prevStep();
    }
    const handleValidation = () =>{
        let valid = true;
        if(document.getElementById('employerName').value === "")
        {
            document.getElementById('employerName').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('employerName').style.backgroundColor = '#ffffff';
            //valid = true;
        }
        // if(document.getElementById('workNumber').value === "")
        // {
        //     document.getElementById('workNumber').style.backgroundColor = '#ffebe6';
        //     valid = false;
        // }else{
        //     document.getElementById('workNumber').style.backgroundColor = '#ffffff';
        //     //valid = true;
        // }
        if(document.getElementById('employerContactNumber').value === "")
        {
            document.getElementById('employerContactNumber').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('employerContactNumber').style.backgroundColor = '#ffffff';
            //valid = true;
        }
        if(document.getElementById('employmentNumber').value === "")
        {
            document.getElementById('employmentNumber').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('employmentNumber').style.backgroundColor = '#ffffff';
            //valid = true;
        }
        if(document.getElementById('employerAddress1').value === "")
        {
            document.getElementById('employerAddress1').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('employerAddress1').style.backgroundColor = '#ffffff';
            //valid = true;
        }
        if(document.getElementById('employerAddressCity').value === "")
        {
            document.getElementById('employerAddressCity').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('employerAddressCity').style.backgroundColor = '#ffffff';
            //valid = true;
        }
        if(document.getElementById('yearEmployed').value === "")
        {
            document.getElementById('yearEmployed').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('yearEmployed').style.backgroundColor = '#ffffff';
            //valid = true;
        }
        if(document.getElementById('monthEmployed').value === "select...")
        {
            document.getElementById('monthEmployed').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('monthEmployed').style.backgroundColor = '#ffffff';
            //valid = true;
        }

        if(document.getElementById('occupation').value === "")
        {
            document.getElementById('occupation').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('occupation').style.backgroundColor = '#ffffff';
            //valid = true;
        }
        if(document.getElementById('employerAddressPostalCode').value === "")
        {
            document.getElementById('employerAddressPostalCode').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('employerAddressPostalCode').style.backgroundColor = '#ffffff';
            //valid = true;
        }
        if(document.getElementById('salaryFrequency').value === "select...")
        {
            document.getElementById('salaryFrequency').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('salaryFrequency').style.backgroundColor = '#ffffff';
            //valid = true;
        }
        if(document.getElementById('salaryDay').value === "select...")
        {
            document.getElementById('salaryDay').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('salaryDay').style.backgroundColor = '#ffffff';
            //valid = true;
        }

        return valid;
    }
    //let firstTIme = 0;
    useEffect(() => {
        if(firstTIme === 0){
            console.log('WAAAAAAAAS Called!');
            window.scrollTo(0,0);
            setFirstTIme(1);
        }
    });
    const options = lookup[salaryFrequency];
    return(
        <>
        <main id="main">
        <div className="container" data-aos="fade-up">
        <div className="section-title">
            <h2>Application</h2>
        </div>
        <section id="application" className="application_section">
            <LoanSummary affordability={affordability} />
            <ProgressBr n ={2}/>
            {error && <MessageBox variant="danger">{error}</MessageBox>}
            <div className="row mb">
                <div className="col-12" data-oas="fade-left" data-oas-delay="100">
                    {/* <h2 className="mb-15">Personal Information</h2> */}
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title mb">
                                <h5 className="text-grey">Employer Information</h5>
                            </div>
                            <div className="row mb">
                                <div className="col-md-4">
                                    <label htmlFor="employerName" className="form-label"><small style={{ color: 'red' }}>*</small> Employer Name</label>
                                    <div className="input-group">
                                        <input name="employerName" id="employerName" type="text" className="form-control" onClick={(e) => {document.getElementById('employerName').style.backgroundColor='#ffffff'}} onChange={e => setemplooyerName(e.target.value)} value={employerName} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="employerContactNumber" className="form-label"><small style={{ color: 'red' }}>*</small> Employer Contact Number</label>
                                    <div className="input-group">
                                        <input name="employerContactNumber" id="employerContactNumber" type="text" className="form-control" onClick={(e) => {document.getElementById('employerContactNumber').style.backgroundColor='#ffffff'}} onChange={e => setemployerContactNumber(e.target.value)} value={employerContactNumber}/>
                                    </div>
                                </div>
                                {/* <div className="col-4">
                                    <label htmlFor="workNumber" className="form-label"><small style={{ color: 'red' }}>*</small> Work Number</label>
                                    <div className="input-group">
                                        <input name="workNumber" id="workNumber" type="text" className="form-control" onClick={(e) => {document.getElementById('workNumber').style.backgroundColor='#ffffff'}} onChange={e => setworkNumber(e.target.value) } value={workNumber}/>
                                    </div>
                                </div> */}

                                <div className="col-md-4">
                                    <label htmlFor="taxNumber" className="form-label">Tax Number</label>
                                    <div className="input-group">
                                        <input name="taxNumber" id="taxNumber" type="text" className="form-control" onChange={e => setTaxNumber(e.target.value)}  value={values.taxNumber}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb">
                                <div className="col-md-4">
                                    <label htmlFor="employerAddress" className="form-label"><small style={{ color: 'red' }}>*</small> Employer Address</label>
                                    <div className="input-group">
                                        <input name="employerAddress1" id="employerAddress1" type="text" className="form-control" onClick={(e) => {document.getElementById('employerAddress1').style.backgroundColor='#ffffff'}} onChange={e => setemployerAddres1(e.target.value)} value={employerAddress1}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="employerAddressCity" className="form-label"><small style={{ color: 'red' }}>*</small> Employer City</label>
                                    <div className="input-group">
                                        <input name="employerAddressCity" id="employerAddressCity" type="text" className="form-control" onClick={(e) => {document.getElementById('employerAddressCity').style.backgroundColor='#ffffff'}} onChange={e => setemployerAddressCity(e.target.value)} value={employerAddressCity}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="employerAddressPostalCode" className="form-label"><small style={{ color: 'red' }}>*</small> Employer Address Postal Code</label>
                                    <div className="input-group">
                                        <input name="employerAddressPostalCode" id="employerAddressPostalCode" type="text" className="form-control" onClick={(e) => {document.getElementById('employerAddressPostalCode').style.backgroundColor='#ffffff'}} onChange={e => setemployerPostalCode(e.target.value)} value={employerAddressPostalCode}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb">
                                {/* <div className="col-4">
                                    <label htmlFor="employerContactNumber" className="form-label"><small style={{ color: 'red' }}>*</small> Employer Contact Number</label>
                                    <div className="input-group">
                                        <input name="employerContactNumber" id="employerContactNumber" type="text" className="form-control" onClick={(e) => {document.getElementById('employerContactNumber').style.backgroundColor='#ffffff'}} onChange={e => setemployerContactNumber(e.target.value)} value={employerContactNumber}/>
                                    </div>
                                </div> */}

                                <div className="col-md-4">
                                    <label htmlFor="employmentNumber" className="form-label"><small style={{ color: 'red' }}>*</small> Employment Number</label>
                                    <div className="input-group">
                                        <input name="employmentNumber" id="employmentNumber" type="text" className="form-control" onClick={(e) => {document.getElementById('employmentNumber').style.backgroundColor='#ffffff'}} onChange={e => setemploymentNumber(e.target.value)} value={employmentNumber}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="occupation" className="form-label"><small style={{ color: 'red' }}>*</small> Occupation</label>
                                    <div className="input-group">
                                        <input name="occupation" id="occupation" type="text" className="form-control" onClick={(e) => {document.getElementById('occupation').style.backgroundColor='#ffffff'}} onChange={e => setoccupation(e.target.value)} value={occupation}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="monthEmployed" className="form-label"><small style={{ color: 'red' }}>*</small> Month Employed</label>
                                    <div className="input-group">
                                        <select name="monthEmployed" id="monthEmployed" className="form-select" aria-label="Bank Name" onClick={(e) => {document.getElementById('monthEmployed').style.backgroundColor='#ffffff'}} onChange={e => setmonthEmployed(e.target.value)} value={monthEmployed}>
                                            <option selected>select...</option>
                                            <option value="1">January</option>
                                            <option value="2">February</option>
                                            <option value="3">March</option>
                                            <option value="4">April</option>
                                            <option value="5">May</option>
                                            <option value="6">June</option>
                                            <option value="7">July</option>
                                            <option value="8">August</option>
                                            <option value="9">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </select>
                                        {/* <input name="monthEmployed" id="monthEmployed" type="text" className="form-control" onClick={(e) => {document.getElementById('monthEmployed').style.backgroundColor='#ffffff'}} onChange={e => setmonthEmployed(e.target.value)} value={monthEmployed}/> */}
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row mb">
                                <div className="col-md-4">
                                    <label htmlFor="yearEmployed" className="form-label"><small style={{ color: 'red' }}>*</small> In Which Year Were You Employed</label>
                                    <div className="input-group">
                                        <input name="yearEmployed" id="yearEmployed" type="number" className="form-control" onClick={(e) => {document.getElementById('yearEmployed').style.backgroundColor='#ffffff'}} onChange={e => setyearEmployed(e.target.value)} value={yearEmployed}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="salaryFrequency" className="form-label"><small style={{ color: 'red' }}>*</small> Salary Frequency</label>
                                    <div className="input-group">
                                        <select name="salaryFrequency" id="salaryFrequency" className="form-select" aria-label="Bank Name" onClick={(e) => {document.getElementById('salaryFrequency').style.backgroundColor='#ffffff'}} onChange={e => setsalaryFrequence(e.target.value)} value={salaryFrequency}>
                                        <option selected>select...</option>
                                        {/* <option value="weekly">
                                            Weekly
                                        </option> */}
                                        <option value="monthly">
                                            Monthly
                                        </option>
                                        {/* <option value="fortnightly">
                                            Fortnightly
                                        </option> */}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="salaryDay" className="form-label"><small style={{ color: 'red' }}>*</small> Salary Day</label>
                                    <div className="input-group">
                                        <select name="salaryDay" id="salaryDay" className="form-select" aria-label="Bank Name" onClick={(e) => {document.getElementById('salaryDay').style.backgroundColor='#ffffff'}} onChange={e => setsalryDay(e.target.value)} value={salaryDay}>
                                        <option selected>select...</option>
                                        {options.map(o =><option key={o.id} value={o.id}>{o.text}</option>)}
                                        {/* <option value="Last Monday">Last Monday</option>
                                        <option value="Last Tuesday">Last Tuesday</option>
                                        <option value="Last Wednesday">Last Wednesday</option>
                                        <option value="Last Thursday">Last Thursday</option>
                                        <option value="Last Friday">Last Friday</option>
                                        <option value="Last Working Day">Last Working Day</option>
                                        <option value="2nd Last Working Day">2nd Last Working Day</option>
                                        <option value="2nd Last Tuesday">2nd Last Tuesday</option>
                                        <option value="2nd Last Wednesday">2nd Last Wednesday</option>
                                        <option value="2nd Last Thursday">2nd Last Thursday</option>
                                        <option value="2nd Last Friday">2nd Last Friday</option>
                                        <option value="4th Friday">4th Friday</option>
                                        <option value="Temp">Temp</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option> */}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb">
                    <div className="col-12">
                        <div style={{float:'right'}}>
                            <div style={{float:'right',marginTop:'3px',marginBottom:'30px'}}>
                                <button type="button" className="get-started-btn" onClick={Previous}><i className="fa fa-chevron-left"></i> Back</button>
                                <button type="button" className="get-started-btn" onClick={Continue}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        </div>
        </main>
        </>
    );
};

export default EmployerInfo;
