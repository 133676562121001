import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LoanSummary from './LoanSummary';
import ProgressBr from './Progress';
import { PDFReader } from 'reactjs-pdf-view';
import SignaturePad from './SignaturePad';
import SignatureCanvas from 'react-signature-canvas';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import styles from '../styles.module.css';
//import scan from  './SignDocument.html';// './Academic License Order Form 082016.pdf';
import axios from 'axios';
import TruIDIframe from './TruIfram';
import ContractContent from './ContractContent';
import { Button, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {savePDF } from "@progress/kendo-react-pdf";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";

const { REACT_APP_NGROK_URL, REACT_APP_LIVE } = process.env;
const API_ADDRESS = `http://${REACT_APP_NGROK_URL}/`;
const API_LOCAL = "https://api.amabuzz.com/"; //"http://localhost:3000/";//  
const API = REACT_APP_LIVE === 'live' ? API_ADDRESS : API_LOCAL;
const ContractScreen = (props)=>{
    //window.scrollTo(0,0);
    let sigPad
    const container = React.useRef(null);
    const pdfExportComponent = React.useRef(null);

    const exportPDFWithMethod = () => {
        // let element = container.current || document.body;
        // savePDF(element, {
        //   paperSize: "auto",
        //   margin: 40,
        //   fileName: `Report for ${new Date().getFullYear()}`,
        // });
        const trimmeDataURL = sigPad.getTrimmedCanvas().toDataURL("image/png");
        setTrimmeDataUrl(trimmeDataURL);
        setTimeout(() =>{
            let gridElement = document.querySelector(".k-container");
            drawDOM(gridElement, {
            paperSize: "A4",
            })
            .then((group) => {
                return exportPDF(group);
            })
            .then((dataUri) => {
                console.log('The doc to save:? ', dataUri.split(";base64,")[1]);
                addNew(dataUri.split(";base64,")[1]);
                setModelView(!modelview);
            });
        },500)
        
      };
    
    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
          pdfExportComponent.current.save();
        }
    };
    const Continue = e => {
        e.preventDefault();
        if(signed){
            props.nextStep({ applicationStatus:'Under Review'});
        }else{
            alert('You need to sign the contract to continue');
        }
    }
    const [numPages, setNumPages] = useState(null);
    const [signed, setSigned] = useState(false);
    const [modelview, setModelView] = useState(false);
    const [trimmeDataURL, setTrimmeDataUrl] = useState(null);
    //const [pageNumber, setPageNumber] = useState(1);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const quickview = () =>{
        setModelView(!modelview);
    }
    const selectedApp = useSelector(state => state.applicationReducer.selectedApp);
    const amorTizations = useSelector(state => state.applicationReducer.amorTizations);
    const amoErr = useSelector(state => state.applicationReducer.amoErr);
    const {RSAIDNumber, appid, affordability} = selectedApp;
    const addNew = async (signatureData) => { 
        let newObject = {};
            Object.assign(newObject, {
            CustomerIDnumber: RSAIDNumber, //"33298345",
            CustomerContract: signatureData,
            CustomerUUID: appid,
            IpAdress: "127.1.0.0"
        });
        setSigned(true);
        console.log("Add new data:",newObject);
        axios.post(`${API}customers/insertSignature`, newObject);

    }
    const hendleExport = () =>{
        const trimmeDataURL = sigPad.getTrimmedCanvas().toDataURL("image/png");
        console.log(trimmeDataURL, trimmeDataURL.length);
        if(trimmeDataURL.length > 130){
            //setSigned(true);
            addNew(trimmeDataURL);
        }else{
            alert('You did not sign, please sign in the pad below to continue');
        }
    }
    const handleClear = () =>{
        sigPad.clear();
    }
  
    return(
        <main id="main">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Application</h2>
                </div>
                <section id="application" className="application_section">
                    <LoanSummary affordability={affordability} />
                    <ProgressBr n ={8}/>
                    <div className="row mb">
                        <div className="col-12" data-oas="fade-left" data-oas-delay="100">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title mb">
                                        <h5 className="text-grey">Contract</h5>
                                    </div>
                                    <div style={{overflow:'scroll',height:600,width:'100%',paddingRight:'5px'}}>
                                        <ContractContent trimmeDataURL={trimmeDataURL} container={container} pdfExportComponent={pdfExportComponent} selectedApp={selectedApp} amoErr={amoErr} amorTizations={amorTizations} />
                                        {/* <div className="form-group">{trimmedDataURL
                                        ? <img className={styles.sigImage}
                                        src={props.trimmedDataURL} />
                                        : null}</div> */}
                                        <a href="javascript:void(0);" onClick={()=>{setModelView(!modelview);}} className="btn btn-primary btnsign " data-idx="4"
                >Sign Here</a>
                 
                                       
                                            <Modal isOpen={modelview} toggle={modelview} className="view-modal">
                                                <ModalHeader className="border-bottom-0 pb-0">
                                                    <Button className="close" color="danger" onClick={quickview}><span aria-hidden="true">x</span></Button>
                                                </ModalHeader>
                                                <ModalBody>
                                                    <Row className="align-items-center">
                                                        <div className="card">
                                                                <div className="card-body">
                                                                    <div className="card-title mb">
                                                                        {/* <h5 className="text-grey">Use your mouse to sign below:</h5> */}
                                                                    </div>
                                                                    <div className={styles.container}>
                                                                        <div className={styles.sigContainer}>
                                                                            <SignatureCanvas penColor='black'
                                                                                canvasProps={{className:styles.sigPad}}
                                                                                ref={(ref) => {
                                                                                    sigPad = ref;
                                                                                }} />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <button className={styles.button} onClick={exportPDFWithMethod}>
                                                                            Save Signature
                                                                        </button>
                                                                        <button className={styles.button} onClick={handleClear}>
                                                                            Clear
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                        </div> 
                                                    </Row>
                                                    {/* <Row>
                                                        <div className="col-12">
                                                            <button className="close" style={{float:'right'}} onClick={quickview} >Close</button>
                                                        </div>
                                                    </Row> */}
                                                </ModalBody>
                                            </Modal>
                                    </div>
                                    {/*trimmeDataURL ?
                                    <div className="example-config">
                                        &nbsp;
                                        <button className="k-button" onClick={exportPDFWithMethod}>
                                            Save
                                        </button>
                                    </div>
                                    :<></>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb">
                    <div className="col-12">
                        <div style={{float:'right'}}>
                            <div style={{float:'right',marginTop:'10px',marginBottom:'30px'}}>
                                <button type="button" className="get-started-btn" onClick={Continue}>Finalise My Application</button>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            </div>
        </main>
    );
};

export default ContractScreen
