import React, { useState, useEffect } from 'react';
import { MessageBox } from './MessageBox';
import ProgressBr from './Progress';
import LoanSummary from './LoanSummary';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { LoadingBox } from './Loading';
import { useSelector } from 'react-redux';

const { REACT_APP_NGROK_URL, REACT_APP_LIVE } = process.env;
const API_ADDRESS = `http://${REACT_APP_NGROK_URL}/`;
const API_LOCAL = "https://api.amabuzz.com/"; // "http://localhost:3000/"; // 
const API = REACT_APP_LIVE === 'live' ? API_ADDRESS : API_LOCAL;

const IncomeExpenses = ({ nextStep, prevStep, newFieldI, newFieldE, handleChange, removeItem, InCome, Expenses, error }) => {
    //window.scrollTo(0,0);
    // let [Income, setIncome] = useState(InCome);
    // let [Expense,setExpense] = useState(Expenses); ref 1-21418045093 MTN Thembile
    const [errAff, setErrAff] = useState();
    const [loading, setLoading] = useState(false);
    const [continueNot, setContinueNot] = useState(false);

    const selectedApp = useSelector(state => state.applicationReducer.selectedApp);
    const { RSAIDNumber, appid, affordability } = selectedApp;
    console.log('Affordability??? => ', affordability);
    const fetchAffordabilityRatio = async () => {
        let url = `${API}customers/calculateAffordability/${appid}`;
        const result = await axios.get(url);
        console.log('>>>=> ', result.data);
        if (result.status === 200) {
            return { data: result.data, ret: true };
        } else if (result.status === 201) {
            return { data: result.data, ret: false };
        }
    }

    const Continue = e => {
        e.preventDefault();
        let afford = {};
        const installMent = affordability[0].installMent;
        if (formValidation()) {
            setLoading(true);
            // fetchAffordabilityRatio().
            //     then(retu => {
            //         if (retu.ret && retu.data.affordabilityPercentage > 30) {
            let someIncome = 0;
            let someExpenses = 0;
            let ratio = 0;
            for (var i = 0; i < InCome.length; i++) {
                afford[InCome[i].name.replaceAll('_', '')] = InCome[i].value;
                someIncome = someIncome + InCome[i].value;
            }
            for (var j = 0; j < Expenses.length; j++) {
                afford[Expenses[j].name.replaceAll('_', '')] = Expenses[j].value;
                someExpenses = someExpenses + Expenses[j].value;
            }
            const netIncome = someIncome - someExpenses
            if(netIncome > 0){
                ratio = 100 - ((installMent / netIncome) * 100);
            }
            if(ratio  > 30){
                setLoading(false);
                nextStep(afford);
            }else{
                setErrAff('Based on the information provided, you do not qualify');
                setContinueNot(true);
                setLoading(false);
            }
        // } else {
        //     setErrAff('Based on the information provided, you do not qualify');
        //     setContinueNot(true);
        //     setLoading(false);
        // }
        // })
        // .catch(error => setErrAff(error));

    } else {
        alert('Please enter values for all fields to continue');
}
    }
const formValidation = () => {
    let canSubmit = true;
    for (var i = 0; i < InCome.length; i++) {
        if (InCome[i].value === '') {
            canSubmit = false;
        }
    }
    for (var j = 0; j < Expenses.length; j++) {
        if (Expenses[j].value === '') {
            canSubmit = false;
        }
    }
    return canSubmit;
}
const Previous = e => {
    e.preventDefault();
    prevStep();
}
const addFieldI = (e) => {
    let sel = document.getElementById('Income').value;
    if (sel !== 'select...') {
        newFieldI(sel);
    }
}
const removeField = (e) => {
    const fld = e.target.id;
    console.log('To remove: ', fld);
    removeItem(e.target.id);
}
const addFieldE = (e) => {
    let sel = document.getElementById('Expenses').value;
    if (sel !== 'select...') {
        newFieldE(sel);
    }
}
const separateWord = (word) => {
    //const samplewrd = 'IncomeMonthlyFixedSalary';
    let sepWrd = '';
    for (var i = 0; i < word.length; i++) {
        if (i === 0) {
            sepWrd = word.charAt(i);
        } else if (word.charAt(i).toUpperCase() === word.charAt(i)) {
            sepWrd += ' ' + word.charAt(i);
        } else {
            sepWrd += word.charAt(i);
        }
    }
    return sepWrd;
}

return (
    <>
        <main id="main">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Application</h2>
                </div>
                <section id="application" className="application_section">
                    <LoanSummary affordability={affordability} />
                    <ProgressBr n={4} />
                    {error && <MessageBox variant="danger">{error}</MessageBox>}
                    {errAff && <MessageBox variant="danger">{errAff}</MessageBox>}
                    {loading && <LoadingBox size={'30px'} color='red' />}
                    <div className="row mb">
                        <div className="col-12" data-oas="fade-left" data-oas-delay="100">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title mb">
                                        <h5 className="text-grey">Income and Expenses</h5>
                                    </div>
                                    <div className="row mb">
                                        <div className="col-md-6" id="containerIn">
                                            <div className="card-title mb">
                                                <h5 className="text-grey"><small style={{ color: 'red' }}>*</small> Income</h5>
                                            </div>
                                            <div className="row mb">
                                                <div className="col-md-6">
                                                    <select name="Income" id="Income" className="form-select">
                                                        <option selected>select...</option>
                                                        <option value="IncomeMonthlyFixedSalary">monthly Fixed Salary</option>
                                                        <option value="IncomeRental">Income Rental</option>
                                                        <option value="IncomeMonthlyOvertime">monthly Overtime</option>
                                                        <option value="IncomeMonthlyOtherIncome">Monthly Other Income</option>
                                                    </select>
                                                </div>
                                                <div><button type="button" className="get-started-btn" id="add_income_field" onClick={addFieldI}>Add</button></div>
                                            </div>
                                            {
                                                InCome.map((val) => {
                                                    return (
                                                        <div><label htmlFor={val.name} className="form-label">{separateWord(val.name)}</label>
                                                            <input name={val.name} type="text" onChange={handleChange(val.name)} className="form-control" value={val.value} />
                                                            <Link to="#" onClick={removeField} id={val.name} >Remove</Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="col-md-6" id="container1">
                                            <div className="card-title mb">
                                                <h5 className="text-grey"><small style={{ color: 'red' }}>*</small> Expenses</h5>
                                            </div>
                                            <div className="row mb">
                                                <div className="col-md-6">
                                                    <select name="Expenses" id="Expenses" className="form-select">
                                                        <option selected>select...</option>
                                                        <option value="ExpenseAccomodation">Expense Accomodation</option>
                                                        <option value="ExpenseFood">Expense Food</option>
                                                        {/* <option value="Insurance Premiums">Insurance Premiums</option> */}
                                                        <option value="ExpenseMaintenance">Expense Maintenance</option>
                                                        <option value="ExpenseUtilities">Expense Utilities</option>
                                                        {/* <option value="Vehicle Instalment">Vehicle Instalment</option> */}
                                                        <option value="ExpenseEducation">Expsense Education</option>
                                                        {/* <option value="expense Electricity">Electricity</option> */}
                                                        {/* <option value="Entertainment">Entertainment</option> */}
                                                        <option value="ExpenseMedical">Expense Medical</option>
                                                        <option value="ExpenseRent">Rent</option>
                                                        {/* <option value="Telephones">Telephones</option> */}
                                                        <option value="ExpenseTransport">Expense Transport/Fuel Cost</option>
                                                        {/* <option value="Water">Water</option> */}
                                                    </select>
                                                </div>
                                                <div>
                                                    <button type="button" className="get-started-btn" id="add_form_field" onClick={addFieldE}>Add</button>
                                                </div>
                                            </div>
                                            {
                                                Expenses.map((val) => {
                                                    return (
                                                        <div><label htmlFor={val.name} className="form-label">{separateWord(val.name)}</label>
                                                            <input name={val.name} type="text" onChange={handleChange(val.name)} className="form-control" value={val.value} />
                                                            <Link to="#" onClick={removeField} id={val.name} >Remove</Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!continueNot ?
                        <div className="row mb">
                            <div className="col-12">
                                <div style={{ float: 'right' }}>
                                    <div style={{ float: 'right', marginTop: '3px', marginBottom: '30px' }}>
                                        <button type="button" className="get-started-btn" onClick={Previous}><i className="fa fa-chevron-left"></i> Back</button>
                                        <button type="button" className="get-started-btn" onClick={Continue}>Next</button>
                                    </div>
                                </div>
                            </div>
                        </div> : null
                    }
                </section>
            </div>
        </main>
    </>
);
};

export default IncomeExpenses;
