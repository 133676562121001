import React from 'react';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import styles from '../styles.module.css';
import { MessageBox } from './MessageBox';
const bank = [
    { id: 'C0E405A4-DF1F-4EFB-A07D-016C59194BFA', name: 'Nedbank' },
    { id: 'E747A210-F00A-4E27-975E-2CF6EFDEB68D', name: 'Capitec Bank' },
    { id: 'F08AFE75-39C1-4590-9389-CBCEC8339CEE', name: 'ABSA' },
    { id: 'B57737C5D-5114-48FF-857A-E51D40079429', name: 'Bank of Athens' },
    { id: '168D23D9-CB7A-47FB-8308-EF85DB4C20DD', name: 'Standard Bank' },
    { id: '0CF9A57A-37BD-4943-94C7-FC7D3955AC1A', name: 'FNB' }
];
const ContractContent = (props) => {
    const bankName = bank.find(b => b.id === props.selectedApp.bankName);
    return (
        <>
            <PDFExport
                ref={props.pdfExportComponent}
                paperSize="A4"
                margin={40}
                fileName={`Report for ${new Date().getFullYear()}`}
                author="Amabuzz"
            >
                <div ref={props.container} className="k-container">
                    <table width="100%" style={{ borderCollapse: 'collapse' }} cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr>
                                <td
                                    style={{ border: '0.75pt solid #000000', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#a6a6a6', textAlign: 'center' }}>
                                    <p style={{ fontSize: '14pt' }}><strong>Terms and Conditions</strong>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p style={{ marginLeft: '2.69pt',textAlign: 'left', fontSize: '10pt' }}><strong>1. Definitions</strong></p>
                    <p style={{ marginLeft: '2.69pt',textAlign: 'left', fontSize: '10pt' }}><strong>The following words will have the following meanings, unless the context clearly indicates another meaning:</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }}>
                        <li style={{ marginLeft: '10.69pt', textAlign: 'justify', paddingLeft: '7.31pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            “Account” means the credit account created by us in respect of the loan that you obtained from us whereby we record all transactions processed against your loan;
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            “Act” means the National Credit Act, No. 34 of 2005, together with its regulations, as amended from time to time;
                        </li>
                    </ol>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} start="3">
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            “Agreement” means the terms and conditions contained in this document, read with the pre-agreement and the application form completed by you, and any changes thereto;
                        </li>
                    </ol>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} start="4">
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            “Debit order” means (at our choice) a debit check whereby we instruct your bank to deduct an amount from your bank account and to pay that amount directly over to us on your behalf.
                            Such an amount will be deducted on your nominated mandated date. The company reserve the right to change the mandated date to an earlier date should the mandated date fall on a public holiday or a weekend
                        </li>
                    </ol>
                    {/* <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} start="5">
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt' }}
                            className="ListParagraph">
                            “Initiation fee” means a fee charged by us to you in order to create a loan for you;
                        </li>
                    </ol> */}
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} start="5">
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            “Personal information” means any information that we receive about you, including but not limited to your name, identity number, contact information, employment and financial information;
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            “Reference rate” means the rate used by us to determine the interest charged by us to you for use of the loan; and
                        </li>
                    </ol>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} start="7">
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            “Service fee” means a fee charged by us to you for the administration of your account.
                        </li>
                    </ol>
                    <p style={{ textAlign: 'justify', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <p style={{ marginLeft:'2.69pt',textAlign: 'left', fontSize: '10pt' }}><strong>2. Our contact details</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }}>
                        <li style={{ marginLeft: '10.69pt', textAlign: 'justify', paddingLeft: '7.31pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            Amabuzz (Pty) Ltd Reg No: 2020/637350/07 is a registered credit provider and authorised financial services provider, of  (hereinafter referred to as “us”, “we”, “our”).
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            Our contact details are:
                            <ol style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} className="awlist1">
                                {/* <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside' }}
                                    className="ListParagraph">
                                    Telephone:  ????
                                </li> */}
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    E-mail: ?????
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph"><span style={{ font: '7pt &#39;Times New Roman&#39;' }}>&nbsp; </span>I have received the
                                    Postal address: P.O. Box 696, Sea Point, Western Cape, 8060, 1st Floor Eagle House, 39 Somerset Road, De Waterkant, Western Cape, 8005
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph"><span style={{ font: '7pt &#39;Times New Roman&#39;' }}>&nbsp; </span>The application
                                    Website address: <a href='https://www.amabuzz.co.za'>www.amabuzz.co.za</a>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <p style={{ textAlign: 'justify', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <p style={{ marginLeft:'2.69pt',textAlign: 'left', fontSize: '10pt' }}><strong>3. Application</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }}>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            By completing and submitting an application, you:
                            <ol style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} className="awlist1">
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    are applying to us for a loan. This agreement, contains the terms and conditions upon which we are willing to provide such a loan to you; and
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    confirm that you have read and understand these terms and conditions, the meaning and consequences of this agreement; and
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    confirm that you are the person whose details you have given to us as the applicant in the application form.
                                </li>
                            </ol>
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            All information that you provide us with must be truthful, complete, accurate and correct. You must immediately notify us if any of your information changes. If your application is not complete or if you don’t provide us with any required documentation, we may contact you to obtain the required information or documentation; or we may approve or reject your application.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            At the time of you applying to us for a loan or, should your application be successful, at any time during the existence of this agreement, you must immediately notify us if:
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            Your application for a loan is subject to our credit approval criteria and to the conditions for granting credit as set out in the Act. We are, however, not obliged to grant your application.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            You consent to us obtaining any information or documentation directly from your employer, bank, credit bureau or any other source for the purposes of assessing your application.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            you will provided with a detail state of account as well as the terms and conditions. The statement of account will set out the loan amount, your interest rate and it will show you how much interest and fees you will pay over the loan period.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            Subject to the provisions of the Act, we may from time to time change the terms of this agreement. If we do, we will notify you hereof and upload the new terms onto our website. If you do not agree with the new terms, you must immediately let us know so that we may close your account (you will remain liable for the outstanding balance on your account). Any other changes made to this agreement must either be recorded by us telephonically and thereafter confirmed by us in writing or must be agreed to in writing in order to be valid and binding. If we change the terms of this agreement, it does not mean that a new agreement will automatically come into place.
                        </li>
                    </ol>
                    <p style={{ textAlign: 'justify', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <p style={{ marginLeft:'2.69pt',textAlign: 'left', fontSize: '10pt' }}><strong>4. Advance of loan amount</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }}>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If your application is approved, the loan amount for which you qualify and as requested by you will be paid to you as soon as possible through electronic fund transfer and in South African Rand. You will also receive a link to your completed signed terms and conditions. Under no circumstances will a cash payment be made.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            In the event of a loan application that is not expressly linked to the purchase by you of a specific product, the full loan amount will be paid directly to you into your nominated bank account.
                        </li>
                    </ol>
                    <p style={{ textAlign: 'justify', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <p style={{ marginLeft:'2.69pt',textAlign: 'left', fontSize: '10pt' }}><strong>5. Interest &nbsp; fees</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }}>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            Subject to the provisions of the Act, we will determine the amount of any interest, fees and other costs that will be charged to your account. These amounts will be shown on your statement.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            The rate of interest that we charge to your account will be reflected on your statement, but will never be more than the maximum annual interest rate permitted by the Act from time to time. The interest rate will be linked to the reference rate and will be fixed for the period of the agreement.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            Interest will be calculated on a daily basis and added to your principal debt (in other words, compounded) on a monthly basis.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If you are in arrears, additional interest will be charged on overdue amounts at the same rate as the interest rate applicable. This arrear interest will be collected with your next monthly debit order.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            We will periodically charge you a service fee. This will apply for as long as your account is open. The amount and frequency of the service fee will be set out in your statement; however, we may change this amount on notice to you. We will, however, never exceed the maximum cap as set out in the Act.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            We may charge you a once-off initiation fee. The amount of the initiation fee will be set out in the statement. You may choose to pay the whole initiation fee with your first account payment. If you choose this option, you must let us know at the time of applying to us for credit, otherwise we will add this fee to your account as part of the principal debt so that you may pay it off in instalments.
                        </li>
                    </ol>
                    <p style={{ textAlign: 'justify', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <p style={{ marginLeft:'2.69pt',textAlign: 'left', fontSize: '10pt' }}><strong>6. Statement</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }}>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            We will periodically send you an electronic statement of account, which will show:
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            all transactions relating to your account;
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            the interest and other charges debited to your account;
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            payments made, as well as the balance outstanding;
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            how much you are in arrears by, if any;
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            the minimum amount payable; and
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            the due date on or by which you must pay the outstanding balance.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            It is your responsibility to check your statement. Unless you notify us within 30 days of the date of your statement that you dispute your liability for any debit appearing on your statement, you will be deemed to be liable therefor. Similarly, you must let us know if any payment made or credit received by you is not reflected on your statement.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            The frequency of statements will be at our discretion, If there has been no activity on your account in a particular statement month, we may elect to not send you any statement in respect of such a statement month.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If you have provided us with an e-mail address, we will send you statements via e-mail. Alternatively, we may send you statements via SMS or MMS, using the cellphone number that you gave us in your application form.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            Non-receipt of statements does not free you from your obligation to pay us. You may obtain your account information from us telephonically or (where applicable) through the self-service portal.
                        </li>
                    </ol>
                    <p style={{ textAlign: 'justify', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <p style={{ margineLeft:'2.69pt',textAlign: 'left', fontSize: '10pt' }}><strong>7. Payment &nbsp; settlement</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }}>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            You must pay at least the minimum amount payable as indicated on your statement by the due date, which amount is made up of the sum of:
                            <ol style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} className="awlist1">
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside' }}
                                    className="ListParagraph">
                                    the instalment
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    any arrears; and
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    fees and charges.
                                </li>
                            </ol>
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            You must pay your account through a debit check. You therefore authorise us (and mandate your bank) to deduct your monthly payments, as well as any other amount that may be due from time to time by you to us in terms of this agreement, from your bank account. If your account is in arrears, you authorise us (and mandate your bank) to also deduct such arrear amount, as well as any other amount that may be due from time to time by you to us in terms of this agreement, from your bank account.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }} className="ListParagraph">
                            I/ hereby authorise you to issue and deliver payment instructions to your banker for collection against my/our abovementioned account at my/our abovementioned bank (or any other bank or branch to which I/we may transfer my/our account) on condition that the sum of such payment instructions will never exceed my/our obligations as agreed to in the Agreement,
                            and commencing on {Object.keys(props.amorTizations).length > 0 ? props.amorTizations.instalment_schedule[0].due_date : ''}. and continuing until this Authority and Mandate is terminated by me/us by giving you notice in writing of not less 20 ordinary working days, and sent by prepaid registered post or delivered to your address indicated below.

                            The individual payment instructions so authorised to be issued must be issued and delivered as follows: monthly, bi-monthly, three monthly, six monthly, annually, weekly, bi-weekly (delete that which is not applicable).

                            In the event that the payment day falls on a Sunday or recognized South African public holiday, the payment day will automatically be the preceding ordinary business day.
                            Furthermore, if there are insufficient funds in my account to meet the obligation, you are entitled to track my account and re-present the instruction for payment as soon as sufficient funds are available in my account.

                            Payment Instructions due in December may be debited against my account on 15th of December.

                            I /We understand that the withdrawals hereby authorised will be processed through a computerized system provided by the South African Banks. I also understand that details of each withdrawal will be printed on my bank statement.
                            Such must contain a number, which number must be included in the said payment instruction and if provided to me should enable me to identify the Agreement. This number must be added to this form in Section F before the issuing of any payment instruction.

                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            We will deem all payments made by you to be made paid on the date that we receive such payment and the payments shall be allocated in the following order:
                            <ol style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} className="awlist1">
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    payment of due or unpaid interest, and thereafter
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    payment of due or unpaid fees and charges (including any insurance), and finally
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    payment of the principal debt, it being agreed that your oldest debt will be paid first.
                                </li>
                            </ol>
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            In the event that the payment day falls on a Saturday, Sunday or recognised South African public holiday, the payment day will automatically be the business day before your usual payment date.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If you make a payment using the incorrect reference, your payment may not be allocated to your account. It is your responsibility to check your statements and to let us know if any payment is not reflecting on your account.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            You may prepay any amount owed to us at any time, however, making a prepayment will reduce your outstanding balance and not entitle you to skip a payment.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If your debit order is unpaid by your bank due to insufficient funds, we may track your account and re-present the instruction for payment as soon as sufficient funds are available. If your debit order is still unpaid by your bank, you must make the minimum payment due as indicated on your statement directly to us by the due date for such payment in order to keep your account from going into arrears.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If your debit order authorisation lapses due to there being no funds available in your bank account, you must contact us to provide us with a new debit order authorisation if you want to continue paying by means of a debit order.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If you wish to settle your account, you must first contact us to obtain a settlement amount. Settling your account means that we will automatically also close your account.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            You must immediately let us know if during the term of this agreement:
                            <ol style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} className="awlist1">
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    You apply for sequestration or to be placed under administration;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    You are placed under curatorship so that you are no longer able to manage your own financial affairs; or
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    You apply for debt review.
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <p style={{ textAlign: 'justify', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <p style={{ marginLeft:'2.69pt',textAlign: 'left', fontSize: '10pt' }}><strong>8. Arrear accounts &nbsp; debt enforcement</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }}>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If your account goes into arrears:
                            <ol style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} className="awlist1">
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    you will be charged default administration costs and any other costs and fees relating to debt collection activities;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    default information will be submitted to the credit bureaus, which may affect your ability to obtain further credit;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    your account may be handed over to debt collection agencies for the recovery of the arrear amount, the costs of which you will be responsible for.
                                </li>
                            </ol>
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If your account has been in arrears for a period as determined by us from time to time, during which period you failed to make any payments on your account, your account will automatically be classified as “written off” and will be closed on notice to you. If this happens, we may choose to also terminate this agreement.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If we have to institute legal action against you in court, you will be liable for all costs incurred, including but not limited to legal costs on the scale “attorney and own client” (which means the costs incurred by us for the attorney’s professional services, plus any expenses and costs incurred by such attorney in trying to recover the arrears plus costs from you), as well as collection charges, tracing fees and taxes hereon.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            We may approach a court to take judgment against you for the debt owed by you to us in terms of this agreement. If we do, a certificate signed by any one of our managers (whose appointment and authority need not be proved) stating the amount that you owe us and the applicable interest rate, shall be accepted as sufficient proof of your indebtedness and shall be deemed correct unless you are able to prove otherwise.

                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            The address that you gave us when completing the application is the address that you choose where legal documents may be served on you. If we are able to serve documents on you electronically, the e-mail address and/or cellphone number that we have on record for you will be used. If you wish to change your physical address, e-mail address and/or cellphone number, you must give us notice hereof and any such change will take effect 7 business days after we receive such notice.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If you have a query or complaint, contact our customer services department for a resolution. If you are not satisfied with the outcome of your query or complaint, you have the right to resolve the matter by way of alternative dispute resolution or you can file any complaints with the National Credit Regulator (contact number 0860 627 627) or make application to the National Credit Tribunal.
                        </li>
                    </ol>
                    <p style={{ textAlign: 'justify', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <p style={{ marginLeft:'2.69pt',textAlign: 'left', fontSize: '10pt' }}><strong>9. Termination</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }}>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If one or more of the following happens:
                            <ol style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} className="awlist1">
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    you don’t pay any amount that is due to us on or before the due date;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    you fail to comply with any term or condition of this agreement, our self-service portal terms or our website terms of use;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    your estate is sequestrated;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    you pass away;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    you make any untrue, misleading or incorrect statement or representation to us;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    you fail to disclose information regarding this agreement or during your application;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    you breach any local or international laws or regulations relating to trade sanctions or financial intelligence and securities;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    you do anything that may prejudice our rights;
                                    then, all amounts owing by you will become due and payable immediately and we may immediately terminate this agreement,
                                    without prejudice to any other right in law that we may have against you (or your estate).
                                </li>
                            </ol>
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            If we exercise our right to terminate this agreement in terms of any of the provisions hereof and there is an outstanding balance on your account, all amounts owing by you will immediately become due and payable by you (unless we make other arrangements with you).
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            You may terminate this agreement at any time by contacting us and informing us hereof. We will provide you with a settlement amount that you must pay us and after receipt and processing of such payment, we will close your account.
                        </li>
                    </ol>
                    <p style={{ textAlign: 'justify', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <p style={{ marginLeft:'2.69pt',textAlign: 'left', fontSize: '10pt' }}><strong>10. Personal information</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }}>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            We will keep your personal information for as long as we need to or have to by law. We will not disclose your personal information to anyone unless we are acting in terms of this agreement or we need to do so in order to comply with this agreement. We will also disclose your personal information where we are obliged to by law, in terms of a court order; or where we have your consent.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            You agree and consent that we may process, record and/or disclose your personal information, including details of any transactions on your account, to:
                            <ol style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} className="awlist1">
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    assess any application that you make with us for credit or other financial services (we will sometimes use a standard credit-scoring or other automated decision-making system to do this);
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    manage your loan (and policies, if any), and make decisions on questions about any application, agreement or correspondence which you may have with us;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    search credit bureaus, credit reference, fraud prevention agencies’ records or any other third party’s records so that we can manage your account and make decisions about credit, including whether to make credit available, to continue making credit available to you or extend further credit to you;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    carry out, monitor and analyse our business;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    contact you by post, by phone, by e-mail, by SMS or other electronic means or in any other way about other products and services which we consider may interest you, unless you tell us that you would prefer not to receive such offers;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    any person or company working for or with us;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    any insurer, if you arrange insurance through us;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    any organisation which underwrites or supports any of our products which you hold;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    any of our retail partners;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    any guarantor of your obligations under this agreement;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    any payment system under or through which your account is paid;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    any person to whom we transfer any of our rights or obligation under this agreement;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    any person or company (including any direct marketing agencies)with whom we interact in order to supply to you,
                                    or provide to you or market to you any product or service that we believe you may be interested in,
                                    unless you tell us that you would prefer not to receive such marketing;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    any third party who processes your personal information on our behalf
                                    (including third parties who are located and run their business outside of the Republic of South Africa);
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    any third party debt collection agency or attorney appointed to collect any monies you owe us; and
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    anyone you authorise us to give your personal information to.
                                </li>
                            </ol>
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            To the extent lawful, we may transfer, license or otherwise authorise the use of any of your personal information.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            In the event that we hand your account over to a debt collection agency or an attorney for collection or legal action, you agree and consent to us releasing some personal information about you to such third party.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            From time to time we may monitor your account in order to comply with our obligations in terms of financial intelligence and securities laws, as well as international sanctions regulations.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            We may also monitor and record all telephone calls and other interactions with you.
                        </li>
                    </ol>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <p style={{ marginLeft:'2.69pt',textAlign: 'left', fontSize: '10pt' }}><strong>11. Bureaus</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <ol style={{ margin: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }}>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            You consent to and agree that we may at any time (even if you are no longer have your account with us, as permitted by law):
                            <ol style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} className="awlist1">
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    make enquiries to obtain or confirm any information about you;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    submit to any credit or other bureau (or a third party) any information about you or provided to us by you in your application or about the application, opening and closing of this agreement and account;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    seek, verify and receive information from any credit bureau or third party (with whom you have financial relations at any time) when assessing your application or your creditworthiness, and also at any time during the existence of your account;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    provide, disclose and register the existence of this agreement and any personal information, and details relating thereto, to any credit bureau or third party (with whom you have financial relations at any time) sharing positive and negative information about you or your credit account, including any non-compliance with this agreement.
                                </li>
                            </ol>
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            You acknowledge and understand that a credit bureau will provide us with credit profile information and possibly a credit score reflecting your creditworthiness.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            Subject to the provisions of the Act, we will be entitled to obtain and disclose your information:
                            <ol style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt', listStyleType: 'decimal' }} className="awlist1">
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    within our group of companies or associated companies;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    if we think it is necessary or may be of benefit to you;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    where we are legally compelled to do so;
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10ptt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    where it is in the public interest to disclose; or
                                </li>
                                <li style={{ marginLeft: '18pt', textIndent: '-18pt', fontSize: '10pt', listStylePosition: 'inside',marginRight:'8.69pt' }}
                                    className="ListParagraph">
                                    where our interests require disclosure.
                                </li>
                            </ol>
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            The bureaus may record details of our searches about you. We will also release information to them about any account that you hold with us, including information about any payments you have missed. Information held by them may be linked to other people with whom you have a financial association. If you give us false or inaccurate information and we suspect fraud, we will inform them thereof.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            You have the right to challenge information reflected at the credit bureau. You have the right to contact the credit bureau, have your credit record disclosed to you and to have inaccurate information corrected.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            The credit bureaus used by us can be contacted at: Transunion Credit Bureau, Wanderers Office Park, 52 Corlett Drive; Illovo, Tel: 011 214 6000; Experian, Experian House, Ballyoaks Office Park, 35 Ballyclare Drive, Bryanston, Tel: 011 799 3400; Compuscan, Compuscan House, 3 Neutron Ave, Techno Park, Stellenbosch, Tel: 021 888 6000; XDS, 11-13 St. Andrews Street, Oakhurst Building, Parktown, Johannesburg, Tel: 011 645 9100.
                        </li>
                        <li style={{ marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', fontSize: '10pt',marginRight:'8.69pt' }}
                            className="ListParagraph">
                            We cannot be held responsible for any loss that you may suffer or damage that you may incur caused by any credit bureaus or agency.
                        </li>
                    </ol>
                    <p style={{ textAlign: 'center', fontSize: '8pt' }}>
                        &nbsp;
                    </p>
                    <div style={{ pageBreakBefore: 'always', marginLeft: '10.8pt', textAlign: 'justify', paddingLeft: '7.2pt', marginRight: '10.8pt', paddingRight: '7.2pt' }}  >

                        <div>
                            <table border="0" cellspacing="0" cellpadding="0" width="100%">
                                <tbody>
                                    <tr>
                                        <td valign="top" style={{ textAlign: 'left', fontSize: '12pt' }}>
                                            <p><strong>AN AUTHORITY   Given by:</strong></p>
                                            <p><strong style={{ fontSize: '14pt', fontVariantCaps: 'small-caps' }}>customer details:</strong></p>
                                            <p>
                                                Name &amp; Surname:{props.selectedApp.firstName + ' ' + props.selectedApp.lastName}</p>
                                            <p>
                                                Address: {props.selectedApp.homeAddress1}</p>
                                            <p>ID no: {props.selectedApp.RSAIDNumber}</p>
                                            <p>Contact Number: {props.selectedApp.mobileNumber}</p>
                                            <p>Payment Frequency: MONTHLY</p>
                                            {/* <p>
                                                Date Adjustment
                                                Rule (Tick which option is applicable): (<strong>X</strong>) Yes <strong>OR</strong> (&nbsp; )&nbsp; No
                                            </p>
                                            <p>Collection date: 24 November 2020</p> */}
                                        </td>
                                        <td valign="top" style={{ textAlign: 'justify' }}>
                                            <p className="MandateFontP" style={{ textAlign: 'right' }}><strong style={{ fontSize: '14pt', fontVariantCaps: 'small-caps', textAlign: 'left' }}>banking details:</strong></p>
                                            <p className="MandateFontP" style={{ textAlign: 'right' }}>Employer Name: {props.selectedApp.employerName}</p>
                                            <p className="MandateFontP" style={{ textAlign: 'right' }}>Employee No: {props.selectedApp.employmentNumber}</p>
                                            <p className="MandateFontP" style={{ textAlign: 'right' }}>Bank Name: {bankName.name}</p>
                                            <p className="MandateFontP" style={{ textAlign: 'right' }}>Branch Code: {props.selectedApp.bankBranch}</p>
                                            <p className="MandateFontP" style={{ textAlign: 'right' }}>Account No: {props.selectedApp.bankAccountNumber}</p>
                                            <p className="MandateFontP" style={{ textAlign: 'right' }}>Account Type: {props.selectedApp.bankAccountType === '1' ? 'Savings' : 'Current'}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {props.amoErr && <MessageBox variant="danger">{props.amoErr}</MessageBox>}
                        {Object.keys(props.amorTizations).length > 0 ?
                            <>
                                <p><strong>REPAYMENT SCHEDULE: </strong></p>
                                <table border="1" cellspacing="0" cellpadding="0" width="100%">
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left' }}>
                                                <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>
                                                    Index
                                                </p>
                                            </td>
                                            <td style={{ textAlign: 'left' }}>
                                                <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>DUE DATE</p>
                                            </td>
                                            <td style={{ textAlign: 'left' }}>
                                                <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>FINANCED FEES</p>
                                            </td>
                                            <td style={{ textAlign: 'left' }}>
                                                <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>
                                                    INSTALMENT FEES
                                                </p>
                                            </td>
                                            <td valign="top" style={{ textAlign: 'left' }}>
                                                <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>
                                                    PRINCIPAL
                                                </p>
                                            </td>
                                            <td valign="top" style={{ textAlign: 'left' }}>
                                                <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>
                                                    INTEREST
                                                </p>
                                            </td>
                                            <td valign="top" style={{ textAlign: 'left' }}>
                                                <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>TOTAL INSTALMENT</p>
                                            </td>
                                        </tr>
                                        {props.amorTizations.instalment_schedule.map((amo, index) =>
                                            <tr>
                                                <td style={{ textAlign: 'left' }}>
                                                    <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>{amo.instalment_number}</p>
                                                </td>
                                                <td style={{ textAlign: 'left' }}>
                                                    <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>{amo.due_date}</p>
                                                </td>
                                                <td style={{ textAlign: 'left' }}>
                                                    <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>{amo.financed_fees}</p>
                                                </td>
                                                <td style={{ textAlign: 'left' }}>
                                                    <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>R {props.amorTizations.monthly_fee}</p>
                                                </td>
                                                <td style={{ textAlign: 'left' }}>
                                                    <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>R {amo.capital_instalment}</p>
                                                </td>
                                                <td style={{ textAlign: 'left' }}>
                                                    <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}> {props.amorTizations.total_interest}</p>
                                                </td>
                                                <td style={{ textAlign: 'left' }}>
                                                    <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>R {amo.total_instalment}</p>
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td colSpan="6" style={{ textAlign: 'right', padding: '8px' }}>Total Receivable</td>
                                            <td style={{ textAlign: 'left' }}><p style={{ fontSize: '12px', paddingTop: '18px', paddingLeft: '2px', fontWeight: '700' }}>R {props.amorTizations.total_receivable}</p></td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style={{ textAlign: 'left' }}>
                                                <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}><strong>
                                                    BANK STATEMENT REFERENCE:
                                                </strong></p>
                                            </td>
                                            <td colspan="6" valign="top" style={{ textAlign: 'left' }}>
                                                <p style={{ fontSize: '12px', padding: '2px', fontWeight: '700' }}>Amabuzz</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table></> : null}
                    </div>
                    <div id="divsig4"></div>
                    <div className="form-group">{props.trimmeDataURL
                        ? <img className={styles.sigImage}
                            src={props.trimmeDataURL} />
                        : null}
                    </div>
                </div>
            </PDFExport>
        </>

    );
};
export default ContractContent;