import React from 'react';
import { connect } from 'react-redux';

const LoanSummary = (props) =>{

    return (
        <div style={{border:'1px solid #ccc',padding:'8px 0px 0px 8px',marginBottom:'5px'}}>
            <div style={{fontSize:'16px',fontWeight:'bold'}}>Loan Summary:</div>
            <div className="row mb">
                <div className="col-md-4">Loan Amount: R {props.selectedApp.affordability[0].loan1}</div>
                <div className="col-md-4">Loan Terms: {props.selectedApp.affordability[0].loanTerms} Month(s)</div>
                <div className="col-md-4">Loan Installment: R {Math.ceil(props.selectedApp.affordability[0].installMent)} Monthly</div>
            </div>
        </div>
    );
};
const mapStateToProps = state => ({
    selectedApp: state.applicationReducer.selectedApp,
  }); 
export default connect(mapStateToProps, null)(LoanSummary);
