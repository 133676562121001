import React  from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {resetPword} from '../reducers/core/index';
import { LoadingBox } from './Loading';
import { MessageBox } from './MessageBox';

const ResetPassword = (props) =>{
    //window.scrollTo(0,0);
    //console.log('Where is: ', props.location.search);
    const arrpara = props.location.search.split('?')[1].split('&');
    const code = arrpara[0].split('=')[1];
    const RSAIDNumber = arrpara[1].split('=')[1];
    console.log('Where is: ', code, RSAIDNumber);
    const resetPwd = (event) => {
        event.preventDefault();
        let password = document.getElementById("password").value;
        let confirmpwd = document.getElementById('confirmpassword').value;
        if(password === confirmpwd && password !== ''){
            props.resetPword({code, RSAIDNumber, password}); //not only pwd, security string as well
        }else{
            alert('Password and confirm password are different, please try again');
        }
    }
    return(
        <main id="main">
            <div className="container" data-oas="fade-up">
                <section id="login" className="login">
                    <div className="section-title pt">
                        <p>Reset Password</p>
                    </div>
                    <div className="col-md-12 row">
                        <div className="col-md-6">
                            <form className="login-form">
                                {props.sending && <LoadingBox />}
                                {props.sucsend && <MessageBox>{props.message}</MessageBox>}
                                {props.error && <MessageBox variant="danger">{props.error}</MessageBox>}
                                <div className="">
                                    <div className="col-mg-6 form-group">
                                        <label>Password</label>
                                        <input type="password" name="password" width="100%" className="form-control" id="password" placeholder="Enter password" data-rule="minlen:4" data-msg="Please enter password" />
                                    </div>
                                    <div className="col-mg-6 form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" name="confirmpassword" width="100%" className="form-control" id="confirmpassword" placeholder="Enter confirm password" data-rule="minlen:4" maxLength="13" data-msg="Please enter confirm password" />
                                    </div>
                                </div>
                                <div className='col-md-12 text-right'>
                                    <button type='submit' className='get-started-btn' onClick={resetPwd}>Reset Password</button>
                                </div>
                                <div className="col-md-6 form-group">
                                    <Link to="/login">Back to log in</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};
const mapStateToProps = state =>({
    sending: state.applicationReducer.sending,
    error: state.applicationReducer.error,
    sucsend: state.applicationReducer.sucsend,
    message: state.applicationReducer.message,
});

const mapDispatchToProps = {
    resetPword,
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);