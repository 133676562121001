import React, { useEffect }  from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {activateEmail} from '../reducers/core/index';
import { LoadingBox } from './Loading';
import { MessageBox } from './MessageBox';

const EmailActivation = (props) =>{
    //window.scrollTo(0,0);
    //console.log('Where is: ', props.location.search);
    const arrpara = props.location.search.split('?')[1].split('&');
    const code = arrpara[0].split('=')[1];
    const RSAIDNumber = arrpara[1].split('=')[1];
    console.log('Where is: ', code, RSAIDNumber);
    useEffect(() =>{
        props.activateEmail({code, RSAIDNumber});
    },[code, RSAIDNumber]); //
    return(
        <main id="main">
            <div className="container" data-oas="fade-up">
                <section id="login" className="login">
                    <div className="section-title pt">
                        <p>Account Activation</p>
                    </div>
                    <div className="col-md-12 row">
                        <div className="col-md-6">
                            {props.loading && <LoadingBox />}
                            {props.message && <MessageBox>{props.message}</MessageBox>}
                            {props.error && <MessageBox variant="danger">{props.error}</MessageBox>}
                            {/* <form className="login-form">
                                {props.sending && <LoadingBox />}
                                {props.sucsend && <MessageBox>{props.message}</MessageBox>}
                                {props.error && <MessageBox variant="danger">{props.error}</MessageBox>}
                                <div className="">
                                    <div className="col-mg-6 form-group">
                                        <label>Password</label>
                                        <input type="password" name="password" width="100%" className="form-control" id="password" placeholder="Enter password" data-rule="minlen:4" data-msg="Please enter password" />
                                    </div>
                                    <div className="col-mg-6 form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" name="confirmpassword" width="100%" className="form-control" id="confirmpassword" placeholder="Enter confirm password" data-rule="minlen:4" maxLength="13" data-msg="Please enter confirm password" />
                                    </div>
                                </div>
                                <div className='col-md-12 text-right'>
                                    <button type='submit' className='get-started-btn' >Reset Password</button>
                                </div>
                                <div className="col-md-6 form-group">
                                    <Link to="/login">Back to log in</Link>
                                </div>
                            </form> */}
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};
const mapStateToProps = state =>({
    loading: state.applicationReducer.loading,
    error: state.applicationReducer.error,
    message: state.applicationReducer.message,
});

const mapDispatchToProps = {
    activateEmail,
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailActivation);