// CORE
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

const Header = () => {
  const customerSignIn = useSelector(state =>state.applicationReducer.customerSignIn);
  const history = useHistory();
  const logoutfnc = () =>{
    localStorage.clear();
    history.push("/");
    window.location.reload(true);
  }
  const locScrol = (id) =>{
    console.log(id.target.id);
    if(id.target.id === 'w'){
      window.location.replace("/#why-us");
      //document.getElementById('why-us').scrollIntoView();
    }else if(id.target.id === 'a'){
      window.location.replace("/#about");
      //document.getElementById('about').scrollIntoView();
    }else if(id.target.id === 'c'){
      window.location.replace("/#contact");
      //document.getElementById('contact').scrollIntoView();
    }
  }
  const toTop = () =>{
    window.scrollTo({top:0, left:0});
  }
  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <h1 className="logo mr-auto"><Link to="index.html">amabuzz</Link></h1>
        <nav className="nav-menu d-none d-lg-block">
          <ul>
            <li className="active"><Link to="/" onClick={toTop}>Home</Link></li>
            <li><Link to="#" onClick={locScrol} id='w'>How it works</Link></li>
            <li><Link to="#" onClick={locScrol} id='a'>Why us</Link></li>
            <li><Link to="#" onClick={locScrol} id='c'>Contact us</Link></li>
          </ul>
        </nav>
        {/*<Link to="courses.html" className="get-started-btn">Apply Now!</Link>*/}
        {customerSignIn.custRet.firstName ?
        <Link to="#" className="get-started-btn" onClick={logoutfnc}>Log out</Link>
                                          :
        <Link to="/login" className="get-started-btn">Log in</Link>
        }
        
      </div>
   </header>
  );
};
export default Header;
