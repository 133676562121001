import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import ProgressBr from './Progress';
import LoanSummary from './LoanSummary';
import TruIDIframe from './TruIfram';
import { MessageBox } from './MessageBox';
import {loadTruIDURL} from '../reducers/core/index';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const { REACT_APP_NGROK_URL, REACT_APP_LIVE } = process.env;
const API_ADDRESS = `http://${REACT_APP_NGROK_URL}/`;
const API_LOCAL = "https://amabuzz.ngrok.io/"; //"https://api.amabuzz.com/"; //  
const API = REACT_APP_LIVE === 'live' ? API_ADDRESS : API_LOCAL;
let config_url = "https://api.amabuzz.com/"; //"http://localhost:3000/"; //

const Documents = (props) => { //{nextStep, prevStep, error, handleChange, values} 
    //window.scrollTo(0,0);
    //const [error, setError] = useState('');
    const [idDocument, setIDDocument] = useState(false);
    const [idDoc, setIdDoc] = useState(false);
    const [banStatement, setBankStatement] = useState('');
    const [paySlip, setPaySlip] = useState(false);
    const [payDoc, setPayDoc] = useState(false);
    const [uploadedDocs, setCustomerDocs] = useState({});
    //const [shown, setShown] = useState(false);
    //const [shownPayslip, setShowPayslip] = useState(false);
    //const customerSignIn = useSelector(state =>state.applicationReducer.customerSignIn);
    //const selectedApp = useSelector(state => state.applicationReducer.selectedApp);
    const {selectedApp, customerSignIn, truID} = props;
    const { affordability} = selectedApp;
    const {custRet} = customerSignIn;
    let upDocs = {
        name: custRet.firstName, 
        surname:custRet.lastName,
        idNumber:custRet.RSAIDNumber, 
        idDocument:idDocument,
        paySlip:paySlip
    };
    const Continue = e => {
        e.preventDefault();
        if( !idDocument){
            alert('You need to upload your ID document to continue');
        }else if(!paySlip){
            alert('You need to upload your payslip to continue');
        }else{
            props.nextStep({idDocument, banStatement, paySlip, applicationStatus:'Under Review'});
        }
    }

    const Previous = e => {
        e.preventDefault();
        props.prevStep();
    }
    const openTruID = e => {
        e.preventDefault();
        window.open('https://www.truidconnect.com/258ki5ywwe2899hmmgtv2jj8g', '', 'width=400,height=600,left=50,top=200');
    }
    const dispatch = useDispatch();
    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
          };
          console.log(fileInfo);
        });
      };
    const newUploadDosc = async(event) =>{
        event.preventDefault();
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('username', custRet.lastName);
        formData.append('name', custRet.firstName);
        formData.append('idNumber', custRet.RSAIDNumber);
        // const fsize = file.size;
        // const fileSize = Math.round((fsize / 1024));
        // console.log('This is the file size::::: ', file.size, ' ===> ', fileSize);
        //CALIDAD doc size is limited to 2M
        //if(fileSize <= 2044){
            if(event.target.id === 'identityDocument'){
                formData.append('file', file);
                formData.append('docType', 'Identy_Document');
                setIDDocument(true);
            }else{
                formData.append('file', file);
                formData.append('docType', 'paySlip');
                setPaySlip(true);
            }
            const url = `${API}customers/uploads`;
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'no-cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer',
                body: formData
            }); 
            console.log('Is there response::::: ',response);
        // }else{
        //     alert('Your document maximum size must be 2MB');
        // }
    }
    const uploadDocs = async (event) =>{
        event.preventDefault();
        const file = event.target.files[0];
        console.log(event, event.target.id);
        const filebase64 = await getBase64(file);
        console.log('Base64 File: ', filebase64);
       
        if(event.target.id === 'identityDocument'){
            setIDDocument(filebase64);
            upDocs.idDocument = filebase64;
        }else if(event.target.id === 'payslip'){
            setPaySlip(filebase64);
            upDocs.paySlip = filebase64;
        }
        
        console.log('Obj 2 send: ', upDocs);
        if(upDocs.idDocument !== '' && upDocs.paySlip !== ''){
            try{
                const formData = new FormData();
                formData.append('username', custRet.lastName);
                formData.append('name', custRet.firstName);
                formData.append('idNumber', custRet.RSAIDNumber);
                formData.append('idDocument', upDocs.idDocument);
                formData.append('paySlip', upDocs.paySlip); 
                const url = `${API}customers/uploads`;
                const response = await fetch(url, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'no-cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer',
                    body: formData
                  });
                // const obj ={
                //     username:custRet.lastName,
                //     name: custRet.firstName,
                //     idNumber: custRet.RSAIDNumber,
                //     idDocument: upDocs.idDocument,
                //     paySlip: upDocs.paySlip
                // }
                // const result = await axios.post(`${API}customers/uploads`, obj, {
                //     headers:{
                //         'Content-Type': 'application/json',
                //         'Access-Control-Allow-Origin':'*',
                //     },});
                console.log('Is there response::::: ',response);
                if(response.status === 0 || response.status === 200){
                    setIdDoc(true);
                    setPayDoc(true);
                    dispatch({type:'SAVE_UPDLOADED_DOCS', payload:upDocs});
                }else{
                    dispatch({type:'SAVE_UPLOADED_DOCS_ERROR', payload:'Error uploading documents, please try again'})
                }
                
                
                
            }catch(e){
                dispatch({type:'SAVE_UPLOADED_DOCS_ERROR', payload:e.message});
            }
        }
    };
    const fetchCustmerDocs = async() =>{
        let url = config_url +`customers/getCustomerDocs/${selectedApp.appid}`;
        const result = await axios.get(url);
        let { data } = result;
        console.log('>>>>==> ', data); 
        setCustomerDocs(data);
        if(data.idDocument){
            setIDDocument(true);
        }
        if(data.paySlip){
            setPaySlip(true);
        }
    }
    useEffect(() =>{
        if(selectedApp && selectedApp.RSAIDNumber && selectedApp.firstName && selectedApp.lastName && (truID.length === 0)){
            const obj = {
                name: selectedApp.firstName + " " + selectedApp.lastName,
                idNumber: selectedApp.RSAIDNumber, //"8901015000111",
                idType: "id",
                nationality: "za",
                mobile: selectedApp.mobileNumber, //"0826791402",
                email: selectedApp.emailAddress, 
                brandId: "d6m634csmx0h2nzhnnuebe4hx",
                webhookUrl: "https://amabuzzsocket.herokuapp.com/amabuzz",
                services: [
                  "a9cxc14lconhuewfjlsr7c1kt",
                  "bb0mkmwztyrmj0gc2w3zgkccb"
                ],
                force:true
            };
            console.log("How about here");
            props.loadTruIDURL(obj);
            fetchCustmerDocs();//fetch docs separatly
        }
    },[loadTruIDURL, selectedApp, selectedApp.RSAIDNumber, selectedApp.firstName, selectedApp.lastName, truID.length]);
    return(
        <>
        <main id="main">
        <div className="container" data-aos="fade-up">
        <div className="section-title">
            <h2>Application</h2>
        </div>
        <section id="application" className="application_section">
            <LoanSummary affordability={affordability} />
            <ProgressBr n ={6}/>
            {props.error && <MessageBox variant="danger">{props.error}</MessageBox>}
            {props.upsucc && <MessageBox>{props.upsucc}</MessageBox>}
            <div className="row mb">
                <div className="col-12" data-oas="fade-left" data-oas-delay="100">
                    <div className="card">
                        <div className="card-body">
                            <div className="row pt">
                                <div className="col-7" data-aos="fade-left" data-aos-delay="100">
                                    <h5 className="card-title">Attachments</h5>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mb">
                                                <div className="col-8">
                                                    <label htmlFor="identityDocument" class="form-label"><small style={{ color: 'red' }}>*</small> Identity Document</label>
                                                    <div className="input-group">
                                                        <input name="identityDocument" accept=".pdf, image/*" onChange={newUploadDosc} class="form-control" type="file" id="identityDocument" width="100%" capture="environment"/>
                                                    </div>
                                                </div>
                                                {uploadedDocs.idDocument ?
                                                <div style={{ color: 'red',fontWeight:'bold' }}>Received </div> :
                                                null}
                                            </div>
                                            {/* <div className="row mb"> className='text-muted'
                                                <div className="col-8">
                                                    <label htmlFor="bankStatement" class="form-label">Bank Statement</label>
                                                    <div className="input-group">

                                                        <button name="downloadBS" className="form-control" onClick={() => setShown(!shown)} id="bankStatement">Download</button>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row mb">
                                                <div className="col-8">
                                                    <label htmlFor="payslip" class="form-label"><small style={{ color: 'red' }}>*</small> Payslip</label>
                                                    <div className="input-group">
                                                        <input name="payslip" accept=".pdf, image/*" onChange={newUploadDosc} class="form-control" type="file" id="payslip" capture="environment"/>
                                                    </div>
                                                </div>
                                                {uploadedDocs.paySlip ?
                                                <div style={{ color: 'red',fontWeight:'bold' }}>Received </div> :
                                                null}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-3">
                                {shown ? <TruIDIframe src="https://www.truidconnect.com/12gnhbgh4jzxx9cf9acpom5wo" /> : null}
                                </div> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb">
                    <div className="col-12">
                        <div style={{float:'right'}}>
                            <div style={{float:'right',marginTop:'10px',marginBottom:'30px'}}>
                                <button type="button" className="get-started-btn" onClick={Previous}><i className="fa fa-chevron-left"></i> Back</button>
                                <button type="button" className="get-started-btn" onClick={Continue}>Next</button>
                            </div>
                        </div>
                    </div>
            </div>
            {/* <div className='row mb'>
                <img src={idDocument} className='card-img-top show' alt='documents' />
            </div>
            <div className='row mb'>
                <img src={paySlip} className='card-img-top show' alt='documents' />
            </div> */}
        </section>
        </div>
        </main>
        </>
    );
};
const mapStateToProps = state =>({
    customerSignIn:state.applicationReducer.customerSignIn,
    selectedApp: state.applicationReducer.selectedApp,
    truID: state.applicationReducer.truID,
    upsucc: state.applicationReducer.upsucc,
});
const mapDispatchToProps ={
    loadTruIDURL,
}
export default connect(mapStateToProps, mapDispatchToProps)(Documents);
