import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import GeneralInfo from './GeneralInfo';
import AddressInfo from './AddressInfo';
import EmployerInfo from './EmployerInfo';
import BankingInfo from './BankingInfo';
import IncomeExpenses from './IncomeExpenses';
import Questions from './Questions';
import Documents from './Documents';
import { Stepper } from '@progress/kendo-react-layout';
import "../../node_modules/@progress/kendo-theme-bootstrap/dist/all.css";
import {saveGeneralInfo, saveAddressInfo, saveEmployerInfo,
        saveBankingInfo, saveIncomeExpenses,saveQuestionsAns, saveDocuments, addData, addIncomeExpense, SaveAppStatus} from '../reducers/core/index';
import TruIDBankStatement from './TruIDBankStatement';
import ContractScreen from './ContractScreen';

const steps = [
    {
      label: "",
      icon: "",
    },
    {
      label: "",
      icon: "",
    },
    {
      label: "",
      icon: "",
    },
    {
      label: "",
      icon: "",
    },
    {
        label: "",
        icon: "",
    },
    {
        label: "",
        icon: "",
    },
    {
      label: "",
      icon: "",
    },
  ];

class Application extends Component  {
    state ={
        step: 0,
        InCome:[{name:'IncomeMonthlyFixedSalary', value:''}],
        
         Expenses:[{name:'ExpenseAccomodation', value:''}],
    }
    prevStep = () => {
        const { step } = this.state;
        this.setState({step: step - 1});
    }
    nextStep = (info) => {
        const { step } = this.state;
        console.log('The step: ', step);
        this.setState({ step: step + 1});
        switch(step){
            case 0:
                // console.log('The step: ', step);
                // info = Object.assign(this.props.selectedApp, info);
                // this.props.SaveAppStatus(info);
                // this.props.addData(info);
                // this.props.history.push('/dashboard');
                // break;
                console.log('The step: ', step, info);
                if(!this.props.selectedApp.appid){
                    //this.props.addData(info);
                    this.props.saveGeneralInfo(Object.assign(info, this.props.selectedApp));
                }
                this.props.addData(info);
                break;
            case 1:
                info = Object.assign(this.props.selectedApp, info);
                console.log('The step: ', step, info);
                this.props.saveAddressInfo(info);
                this.props.addData(info);
                break;
            case 2:
                info = Object.assign(this.props.selectedApp, info);
                console.log('The step: ', step, info);
                this.props.saveEmployerInfo(info);
                this.props.addData(info);
                break;
            case 3:
                info = Object.assign(this.props.selectedApp, info);
                console.log('The step: ', step, info);
                this.props.saveBankingInfo(info, this.props.selectedApp);
                this.props.addData(info);
                break;
            case 4:
                info = Object.assign(info, {loan1:this.props.selectedApp.affordability[0].loan1,
                    loanTerms:this.props.selectedApp.affordability[0].loanTerms, installMent:this.props.selectedApp.affordability[0].installMent});
                console.log('The step: ', step, info);
                this.props.saveIncomeExpenses(info, this.props.Income, this.props.Expenses);
                this.props.addIncomeExpense(info);
                break;
            case 5:
                //info = Object.assign(info, this.props.selectedApp);
                console.log('The step: ', step,info);
                info = Object.assign(info, {applicationReference:this.props.selectedApp.appid, CreditReportStatus: 'Passed', AffordabilityVerificationStatus:'Passed'} );
                this.props.addData(info);
                this.props.saveQuestionsAns(info);
                break;
            case 6:
                console.log('The step: ', step);
                break;
            case 7:
                console.log('The step: ', step);
                break;
            case 8:
                console.log('The step: ', step);
                info = Object.assign(this.props.selectedApp, info);
                this.props.SaveAppStatus(info);
                this.props.addData(info);
                this.props.history.push('/dashboard');
                break;
            default:
                //
        }
    }
    handleChange = input => e => {
        let Income = this.state.InCome;
        let Expenses = this.state.Expenses;
        let indexi = Income.findIndex((x) => x.name === input);
        let indexe = Expenses.findIndex((x) => x.name === input);
        if(indexi >= 0){
            Income[indexi].value = e.target.value;
            this.setState({
                InCome: Income
            });
        }else if(indexe >= 0){
            Expenses[indexe].value = e.target.value;
            this.setState({
                Expenses: Expenses
            });
        }else{
            this.setState({[input]: e.target.value});
        }
    }
    newFieldI = (field) => {
        let Income = this.state.InCome;
        let fld = field; //.replaceAll(' ', '_');
        // field.replace(" ", "_").replace(" ", "_");
        console.log("The array: ", Income, field);
        let bfound = false;
        for(var i = 0; i < Income.length; i++){
            if(Income[i].name === fld){
                bfound = true;
                break;
            }
        }
        if(!bfound){
            this.setState({
                InCome: [...Income,{name:fld, value:""}],
            });
        }
        console.log('Cur State: ', this.state.InCome);
    }
    removeItem = (field) =>{
        if(field.indexOf("Income") === 0){
            const Income = this.state.InCome;
            const newIncome = Income.filter(item => item.name !== field);
            console.log('New Income: ', newIncome);
            this.setState({InCome: newIncome});
        }else if(field.indexOf("Expense") === 0){
            const Expenses = this.state.Expenses;
            const newExpense = Expenses.filter(exp => exp.name !== field);
            this.setState({Expenses: newExpense});
        }
    }
    newFieldE = (field) => {
        let Expenses = this.state.Expenses;
        let fld = field; //.replace(" ", "_").replace(" ", "_").replace("/", "_");
        console.log("The array: ", Expenses, field);
        let bfound = false;
        for(var i = 0; i < Expenses.length; i++){
            if(Expenses[i].name === fld){
                bfound = true;
                break;
            }
        }
        if(!bfound){
            this.setState({
                Expenses: [...Expenses,{name:fld, value:""}],
            });
        }
        console.log('Cur State: ', this.state.Expenses);
    }
    printstate = () =>{
        console.log(this.state.InCome);
    }
    componentDidMount(){
        const {affordability} = this.props.selectedApp;
        console.log(affordability);
        let incom = [];
        let expens = [];
        if(affordability){
            //expenses
            if(affordability[0].hasOwnProperty('ExpenseAccomodation')){
                expens.push({name:'ExpenseAccomodation', value:affordability[0].ExpenseAccomodation})
            }
            if(affordability[0].hasOwnProperty('ExpenseFood')){
                expens.push({name:'ExpenseFood', value:affordability[0].ExpenseFood})
            }
            if(affordability[0].hasOwnProperty('ExpenseMaintenance')){
                expens.push({name:'ExpenseMaintenance', value:affordability[0].ExpenseMaintenance})
            }
            if(affordability[0].hasOwnProperty('ExpenseUtilities')){
                expens.push({name:'ExpenseUtilities', value:affordability[0].ExpenseUtilities})
            }
            if(affordability[0].hasOwnProperty('ExpenseEducation')){
                expens.push({name:'ExpenseEducation', value:affordability[0].ExpenseEducation})
            }
            if(affordability[0].hasOwnProperty('ExpenseMedical')){
                expens.push({name:'ExpenseMedical', value:affordability[0].ExpenseMedical})
            }
            if(affordability[0].hasOwnProperty('ExpenseRent')){
                expens.push({name:'ExpenseRent', value:affordability[0].ExpenseRent})
            }
            if(affordability[0].hasOwnProperty('ExpenseTransport')){
                expens.push({name:'ExpenseTransport', value:affordability[0].ExpenseTransport})
            }
            //incomes
            if(affordability[0].hasOwnProperty('IncomeMonthlyFixedSalary')){
                incom.push({name:'IncomeMonthlyFixedSalary', value:affordability[0].IncomeMonthlyFixedSalary})
            }
            if(affordability[0].hasOwnProperty('IncomeRental')){
                incom.push({name:'IncomeRental', value:affordability[0].IncomeRental})
            }
            if(affordability[0].hasOwnProperty('IncomeMonthlyOvertime'))
            {
                incom.push({name:'IncomeMonthlyOvertime', value:affordability[0].IncomeMonthlyOvertime}); 
            }
            if(affordability[0].hasOwnProperty('IncomeMonthlyOtherIncome')){
                incom.push({name:'IncomeMonthlyOtherIncome', value:affordability[0].IncomeMonthlyOtherIncome}); 
            }
            if(incom.length){
                this.setState({InCome:incom}); //[...this.state.InCome, ...incom]
            }
            if(expens.length){
                this.setState({Expenses:expens}); //[...this.state.Expenses, ...expens]
            }
        }
    }
    render() {
      console.log('==>', this);
        const { step } = this.state;
        switch(step){
            case 0:
                return(
                    <>
                    <GeneralInfo
                        nextStep={this.nextStep}
                        values={this.props.selectedApp}
                    />
                    </>
                );
            case 1:
                return(
                    <>
                    {/* <Stepper value={step} items={steps} /> */}
                    <AddressInfo
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.props.selectedApp}
                    />
                    </>
                );
            case 2:
                return(
                    <>
                    {/* <Stepper value={step} items={steps} /> */}
                    <EmployerInfo
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.props.selectedApp}
                    />
                    </>
                );
            case 3:
                return(
                    <>
                    {/* <Stepper value={step} items={steps} /> */}
                    <BankingInfo
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.props.selectedApp}
                    />
                    </>
                );
            case 4:
                return(
                    <>
                    {/* <Stepper value={step} items={steps} /> */}
                    <IncomeExpenses
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        newFieldI={this.newFieldI}
                        newFieldE={this.newFieldE}
                        removeItem={this.removeItem}
                        handleChange={this.handleChange}
                        InCome={this.state.InCome}
                        Expenses={this.state.Expenses}
                    />
                    </>
                );
            case 5:
                return(
                    <>
                    <Questions
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      handleChange={this.handleChange}
                      values={this.props.QuestionsAns}
                      RSAIDNumber={this.props.selectedApp.RSAIDNumber}
                    />
                    </>
                );
            case 6:
                return(
                    <>
                    {/* <Stepper value={step} items={steps} /> */}
                    <Documents
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.props.selectedApp.uploadedDocs}
                    />
                    </>
                );
            case 7:
                return(
                    <TruIDBankStatement
                        nextStep={this.nextStep} />
                )
            case 8:
                return(
                    <ContractScreen
                        nextStep={this.nextStep}/>
                )
            default:
                //
        }
    }

}
const mapStateToProps = state =>({
    generalInfo: state.applicationReducer.generalInfo,
    addressInfo: state.applicationReducer.addressInfo,
    employerInfo: state.applicationReducer.employerInfo,
    bankingInfo: state.applicationReducer.bankingInfo,
    Income: state.applicationReducer.Income,
    Expenses: state.applicationReducer.Expenses,
    // QuestionsAns: state.applicationReducer.QuestionsAns,
    documents: state.applicationReducer.documents,
    selectedApp: state.applicationReducer.selectedApp,
});
const mapDispatchToProps = {
    saveGeneralInfo,
    saveAddressInfo,
    saveEmployerInfo,
    saveBankingInfo,
    saveIncomeExpenses,
    saveQuestionsAns,
    saveDocuments,
    addData,
    addIncomeExpense,
    SaveAppStatus
}
export default connect(
    mapStateToProps,
    mapDispatchToProps)(Application);
