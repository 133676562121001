import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect} from 'react-redux'
//import { requestSignature } from './../actions'
import { Surface, Path, geometry, drawDOM, exportSVG } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import axios from 'axios';
//const STAGINGIP  = Apis().STAGINGIP

const { Point } = geometry;


const addNew = async (signatureData) => {
    //e.preventDefault();
    
    //let newObject = { signature:signatureData,  IPAddress:"127.0.0.1", CustomerIDnumberData: "4545454545"}
    
    
    //axios.post(`http://${STAGINGIP}:4000/customers/insertSignature`, newObject)

    let newObject = {};
        Object.assign(newObject, {
        CustomerIDnumber: "33298345",
        signature: signatureData,
        IpAdress: "127.1.0.0"
    });
    console.log("Add new data:",newObject)
    axios.post(`https://api.amabuzz.com/customers/insertSignature`, newObject);
  

    /*
    axios({
        method: 'post',
        url: `http://${STAGINGIP}:4000/customers/insertSignature`,
        headers: {
            'Accept':'application/json',
            'Content-Type':'application/json'
                },
        body: newObject
        });    
    */
  }


  



class SignaturePad extends React.Component {
    surface;
    surfaceElement;
    

    componentDidMount() {
        this.surface = Surface.create(this.surfaceElement);
    }

    componentWillUnmount() {
        this.surface.destroy();
    }

    export = () => {
        // Convert the DOM element to a drawing using kendo.drawing.drawDOM
        drawDOM(this.surfaceElement.nativeElement)
            .then((group) => {
                // Render the result as a SVG document
                return exportSVG(group);
            }).then((data) => {
                // Save the SVG document
                saveAs(data, 'signature.svg');
            });
    }

    handleMouseDown = (e) => {
        this.path = new Path({
            stroke: {
                color: '#000000',
                width: 4,
                lineCap: 'round',
                lineJoin: 'round'
            }
        });

        const offset = {
            top: this.surfaceElement.offsetTop,
            left: this.surfaceElement.offsetLeft
        };

        const newPoint = new Point(e.pageX - offset.left, e.pageY - offset.top);

        for (let i = 0; i < 1; i++) {
            this.path.lineTo(newPoint.clone().translate(i * 1, 0));
        }

        this.surface.draw(this.path);
    }

    handleMouseMove = (e) => {
        if (!this.path) {
            return;
        }

        const offset = {
            top: this.surfaceElement.offsetTop,
            left: this.surfaceElement.offsetLeft
        };

        const newPoint = new Point(e.pageX - offset.left, e.pageY - offset.top);

        this.path.lineTo(newPoint);
    }

    handleMouseUp = () => {
        this.path = undefined;
    }

    handleExport = () => {
        // Convert the DOM element to a drawing using kendo.drawing.drawDOM
        drawDOM(this.surfaceElement)
            .then((group) => {
                // Render the result as a SVG document
                return exportSVG(group);
            })
            .then((data) => {
                // Save the SVG document
                //console.log(data);
                addNew(data);
                //saveAs(data, 'signature.svg');
            });
    }

    handleClear = () => {
        this.surface.clear();
    }

    render() {
        return (
            <React.Fragment>
                <div
                    ref={(el) => this.surfaceElement = el}
                    onMouseDown={this.handleMouseDown}
                    onMouseMove={this.handleMouseMove}
                    onMouseUp={this.handleMouseUp}
                    style={{ width: 750, height: 300, border: '1px solid black' }}
                />

                <button className="k-button" onClick={this.handleExport}>Save Signature</button>
                <button className="k-button" onClick={this.handleClear}>Clear</button>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    //state.currentURL = state.router.location.pathname
    return state
  }
  const mapDispatchToProps = (signature) => {

    // return {
    //     //Dispatch Action
    //   getSignature: () => signature(requestSignature()),
    // };
  };
export default connect(mapStateToProps, mapDispatchToProps)(SignaturePad)